
const Loader = () => {
    return (
        <div className="pre-loader">
            <div className="loader"></div>
            <i className="fa fa-spinner fa-spin"></i>
        </div>
    )
}

export default Loader