export const LOADER = 'LOADER';
export const GET_GLOBAL_METRIC = 'GET_GLOBAL_METRIC';
export const STATEMENT_LISTING = 'STATEMENT_LISTING'
export const SELECTED_LISTING = 'SELECTED_LISTING'
export const GET_TIERS = 'GET_TIERS'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const SAVE_REQUIRED = 'SAVE_REQUIRED'
export const FILTER_LISTING = 'FILTER_LISTING'
export const SELECTED_AREA = 'SELECTED_AREA'
export const CLIENT_METRIC_DATA = 'CLIENT_METRIC_DATA'
export const CLIENT_HEADING = 'CLIENT_HEADING'
export const ACTIVE_METRIC = 'ACTIVE_METRIC'
export const ACTIVE_METRIC_VALUE = 'ACTIVE_METRIC_VALUE'
export const CLIENT_DEMO_POINTS = 'CLIENT_DEMO_POINTS'
export const CLIENT_PSYCHO_POINTS = 'CLIENT_PSYCHO_POINTS'
export const CLIENT_TOTAL_POINTS = 'CLIENT_TOTAL_POINTS'
export const CLIENT_SCORES = 'CLIENT_SCORES'
export const CET_CLIENTS = 'CET_CLIENTS'
export const GET_IDEAS = 'GET_IDEAS'
export const CLIENT_VALUE_GRID = 'CLIENT_VALUE_GRID'
export const GAP_ANALYSIS_HEADING = 'GAP_ANALYSIS_HEADING'
export const GAP_ANALYSIS_VALUE = 'GAP_ANALYSIS_VALUE'
export const GAP_ANALYSIS_SCORES = 'GAP_ANALYSIS_SCORES'
export const TIER_TRACKER_CLIENT = 'TIER_TRACKER_CLIENT'
export const TRACKER_IDEAS_LIST = 'TRACKER_IDEAS_LIST'
export const TRACKER_HEADING_LIST = 'TRACKER_HEADING_LIST'
export const TRACKER_GRID_DATA = 'TRACKER_GRID_DATA'
export const TRACKER_GRID_DATA_VALUES = 'TRACKER_GRID_DATA_VALUES'
export const USER_PROFILE = 'USER_PROFILE'
export const RESPONSIBLE_PERSONS = 'RESPONSIBLE_PERSONS'
export const EMPTY_REDUX_DATA = 'EMPTY_REDUX_DATA'
export const SET_SELECTED_SCORING = "SET_SELECTED_SCORING"
