import DemographicMetric from './demographicMetrics'

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'


import {geMetricHeadingData, getClientMetricData, recentStatementListing} from './../../../redux/actions/actionCreators'

const Metric = () => {

    const dispatch = useDispatch()

    const { selectedListing, constants,helper } = useSelector((state) => state?.dataPack)

    const { DEMOGRAPHIC }  = constants?.METRIC_TYPE

    useEffect( async() => {
        helper?.setTitle();
        /** get statement listing data */
        if( !selectedListing )
            await dispatch(recentStatementListing(getClientMetricData, DEMOGRAPHIC))
        else
            await dispatch(getClientMetricData(selectedListing?.value, DEMOGRAPHIC))

        /** get all metrix data */
        await dispatch(geMetricHeadingData(DEMOGRAPHIC))
        
    }, dispatch)
    
    return (
        <DemographicMetric />
    )
}

export default Metric