import React, {useState, useRef,useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";

import { saveIdea,updateIdea } from "./../../../redux/actions/actionCreators";

/** popup button and popover */
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const Service = (props) => {

    const dispatch = useDispatch()
    const inputRefs = useRef()
    const closeRef = useRef();

    const {constants } = useSelector((state) => state?.dataPack)



    const [title,setTitle] = useState(props?.oldValue);
    const [idea_type,setIdeaType] = useState(props?.type);

    useEffect(() => {
        setTitle(props?.oldValue)
        setIdeaType(props?.type)
    }, [props?.oldValue,props?.type])


    /**
     * add new client
     * 
     * @param {event} el 
     * @param
     */
    const handleSubmitEvent=()=>{
   
            let data  = {
                'idea_title': title,
                'idea_type': parseInt(idea_type)
            }
            if( parseInt(props?.id) ){
            /** update wow idea function calling */
            dispatch(updateIdea(data, props?.id,props.type))
            }
            else{
                /** add wow idea function calling */
                dispatch(saveIdea(data,props.type))
            }

            setTitle('');
            setIdeaType(idea_type);
            /** close modal */
            closeRef.current.click();
        }

 
    return (
        <Popup dark onOpen={()=>inputRefs.current.focus()} trigger={
            props.id?
            <i className="fa-solid fa-pen-to-square"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit"></i>
            :
        <button className="btn button--save btn-success pull-right text-white px-3" ><i className="fas fa-plus"></i> Add New { props?.title}</button>
        } modal>
            {close => (<>
                    <div className="modal show">
                        <button className="close" onClick={close}>
                            &times;
                        </button>
                        <div className="header text-left"> {
                            props.id? "Edit"
                            : "Add"
                        } Wow Idea </div>
                        
                        <div className="content">
                            <div>
                                <label>{props?.title} Title*</label>
                                <div className="form-group">
                                    <input ref={inputRefs} required type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} name="title" className="form-control" placeholder={`Add ${props?.title} title here`} maxLength="225" />
                                </div>
                           
                                <div className="form-group mt-3">
                                    <select  className="form-select" aria-label="Platinum options" value={idea_type}  onChange={(e) => setIdeaType(e.target.value)} >      
                                       <option value={constants?.WOW_IDEAS?.SERVICE}>SERVICE</option>
                                       <option value={constants?.WOW_IDEAS?.MARKET}>MARKET</option>
                                    </select>                                   
                            
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="actions">
                        <button onClick={()=>handleSubmitEvent()} className="button"> Submit </button>
                        <button ref={closeRef} className="button"
                            onClick={() => {
                                close();
                            }}
                        >
                            close
                        </button>
                    </div>
                    </>
            )}
        </Popup>
    );



 
};

export default Service;