const Error = ({ title, date }) => {

    const BASE_URL = process.env.REACT_APP_APP_URL;

    return (
        <div className="notfound-page">
            <div className="wrapper">
                <h1>404!</h1>
                <h5>Sorry, Page not found</h5>
                <p>Which page you are looking for might have been removed had its name changed or is temporarily unavailable</p>
                <div className="buttons">
                    <a href={BASE_URL}><i className="fas fa-home"></i> Go to Home</a>
                    
                </div>
                
            </div>
        </div>
    )
}

export default Error