import React, { useEffect } from 'react'
import GapAnalysis from './GapAnalysis'
import { useDispatch, useSelector } from 'react-redux'

/** importing api actions functions */
import {getClients} from './../../../redux/actions/actionCreators'

import { getGapAnalysisHeading, getGapAnalysisData } from './../../../redux/actions/ListActions'

const Index = () => {

    const { selectedListing,helper } = useSelector((state) => state?.dataPack)

    const dispatch = useDispatch()
    
    useEffect( async() => {
        helper?.setTitle()
        /** get all clients list */
        await dispatch(getClients(selectedListing?.value))

        /** get saved data */
        await dispatch(getGapAnalysisHeading())

         /** get all clients list */
         await dispatch(getGapAnalysisData(selectedListing?.value))

    }, dispatch)
   
    return (
        <GapAnalysis />
    )
}

export default Index