import React, { useState } from "react";
import Heading from "./../../../components/layouts/heading";
import { useDispatch, useSelector } from "react-redux";
import {
  saveTrackerData,
  submitTrackerGridData,
} from "./../../../redux/actions/ListActions";
import { saveRequired } from "./../../../redux/actions/actionCreators";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";

const Tracker = () => {
  const dispatch = useDispatch();

  const [range, setRange] = useState(moment().quarter());
  const {
    constants,
    actionRequired,
    selectedListing,
    tierTrackerClients,
    trackerIdeasList,
    trackerHeadingList,
    trackerGridData,
  } = useSelector((state) => state?.dataPack);

  const filterValues = constants?.TRACKER_FILTER_VALUES;
  let filterData = filterValues
    ?.filter((list) => list?.value == range)
    ?.values()
    ?.next()?.value;

  return (
    <>
      {<Heading title={"VIP Wow Tracker"} />}
      <div className="wow-tracker-table-wrapper table-wrapper" >
        <div className="checkbox-table-wrapper">
          <div className="checkbox-table-head d-flex flex-wrap align-items-center justify-content-center justify-content-sm-between px-4 py-3">
            <Select
              className="tracker-filter"
              options={constants?.TRACKER_FILTER}
              placeholder="Select Range"
              value={setRangeValue()}
              onChange={(el) => setRange(el?.value)}
            />
            <div className="add-new-wrapper d-flex flex-wrap align-items-center justify-content-between ms-3 ms-sm-0">
              <button
                type="button"
                className={`btn text-white ${actionRequired && "notify"}`}
                onClick={(el) => handleSubmitEvent(el)}
              >
                <i className="fa-solid fa-floppy-disks"></i> Save
              </button>
            </div>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-hover mb-0">
            <thead>
              <tr>
                <th space="col">Client</th>
                <th scope="col">Client Address</th>
                <th scope="col">Client Birthday & Anniversary</th>
                {renderTrackerHeading(filterData)}
              </tr>
            </thead>
            <tbody>{renderClient(filterData)}</tbody>
          </table>
        </div>
      </div>
    </>
  );

  function setRangeValue() {
    let findData = constants?.TRACKER_FILTER?.filter(
      (list) => list?.value == range
    );
    return findData?.values()?.next()?.value ?? {};
  }

  /**
   * render client list with heading data
   *
   * @param {*} NA
   * @returns
   */

  function renderTrackerHeading(filterData) {
    return trackerHeadingList?.map((heading, index) => {
      if (filterData?.range?.includes(heading?.id)) {
        return (
          <th scope="col" key={index?.toString()}>
            {heading?.month}
          </th>
        );
      }
    });
  }

  /**
   * render client list with heading data
   *
   * @param {*} NA
   * @returns
   */
  function renderClient(filterData) {
    return tierTrackerClients?.map((client) => {
      const { clients } = trackerGridData;
      let findClient = clients?.filter((list) => list?.id == client?.id);
      let isClient = findClient?.values()?.next()?.value;
      return (
        <tr>
          <td>
            <div style={{ alignItems: "center" }}>
              <h5>{client?.name}</h5>
              <div>
                <p
                  className={`border text-white rounded mt-3 ${(client?.tier_type).toLowerCase()}-btn`}
                >
                  {client?.tier_type}
                </p>
              </div>
            </div>
          </td>
          <td>
            <input
              type="text"
              className="form-control"
              value={isClient && isClient?.address}
              onChange={(el) =>
                handleClientDetailsChangeEvent(el, client, "address")
              }
            />
          </td>
          <td>
            <span className="client-birthday">
              Birthday:
              <span></span>
            </span>
            <DatePicker
              placeholderText="mm/dd/yyyy"
              selected={isClient && isClient?.birthday?new Date(isClient?.birthday):null}
              isOpen={true}
              calendarClassName=""
              className="form-control mt-1 mb-2"
              onChange={(el) =>
                handleClientDetailsChangeEvent(el, client, "birthday")
              }
            />

            <span className="client-anniversary">
              Anniversary:
              <span></span>
            </span>
            <DatePicker
              placeholderText="mm/dd/yyyy"
              selected={
                isClient && isClient?.aniversery
                  ?new Date(isClient?.aniversery)
                  : null
              }
              isOpen={true}
              calendarClassName=""
              className="form-control mt-1 mb-2"
              onChange={(el) =>
                handleClientDetailsChangeEvent(el, client, "aniversery")
              }
            />
          </td>
        
          {renderTrackerHeadingData(client, filterData)}
         
        </tr>
      );
    });
  }

  /**
   * render tracker heading data
   *
   * @param {obejct} client
   * @returns
   */
  function renderTrackerHeadingData(client, filterData) {
    const { wow_tracker } = trackerGridData;

    return trackerHeadingList?.map((heading, index) => {
      if (filterData?.range?.includes(heading?.id)) {
        let findTracker = wow_tracker?.filter(
          (list) =>
            list?.client_id == client?.id && list?.tracker_id == heading?.id
        );

        return (
          <td key={index?.toString()}  style={{ overflowY: 'auto' }}  >
            <Select
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
            menuPortalTarget={document.body}
            options={renderServiceData(client)}
            isMulti
            value={findSelectedData(findTracker)}
            onChange={(el) => {
                handleDataSelectEvent(el, client, heading);
            }}
            />
          </td>
        );
      }
    });
  }

  function findSelectedData(trackerData) {
    let data = [];

    trackerData?.map((option) => {
      let idea_details = trackerIdeasList?.filter(
        (list) => list?.id == option?.idea_id
      );
      idea_details = idea_details?.values().next().value;

      data?.push({ value: option?.idea_id, label: idea_details?.idea_title });
    });
    return data;
  }

  /**
   * render tracker idea list
   * @param {object} findTracker
   *
   * @returns
   */
  function renderTrackerIdeaList() {
    return trackerIdeasList?.map((idea, index) => {
      return (
        <option key={index?.toString()} value={idea?.id}>
          {idea?.idea_title}
        </option>
      );
    });
  }

  function renderServiceData(client) {
    if (trackerIdeasList) {
      return trackerIdeasList
        ?.filter((list) => list[client?.tier_type] == 1)
        ?.map((value) => {
          return {
            value: value?.id,
            label: value?.idea_title,
          };
        });
    }
  }

  /**
   * save client change data to store
   *
   * @param {event} el
   * @param {object} client
   * @param {string} type
   * @return
   */
  function handleClientDetailsChangeEvent(el, client, type) {
    let clients = trackerGridData["clients"] ?? [];

    let index = clients?.findIndex((list) => list?.id == client?.id);

    if (index >= 0) {
      if (type === "address") {
        clients[index][type] = el.target.value;
      } else {
        clients[index][type] = moment(el).format("YYYY-MM-DD");
      }
    } else {
      clients?.push({
        id: client?.id,
        address: "address" === type && el.target.value,
        birthday: "birthday" === type && moment(el).format("YYYY-MM-DD"),
        aniversery: "aniversery" === type && moment(el).format("YYYY-MM-DD"),
      });
    }

    trackerGridData["clients"] = clients;

    /** save client trcaker into mamories  */
    dispatch(saveTrackerData(trackerGridData));
    /** save action notification */
    dispatch(saveRequired(true));
  }

  /**
   * save client change data to store
   *
   * @param {event} el
   * @param {object} client
   * @param {string} type
   * @return
   */
  function handleDataSelectEvent(el, client, heading) {
    let collection = el;
    let trackers = trackerGridData["wow_tracker"] ?? [];
    let filteredArray = [];
    let removedItemsArray = [];
    // Mass Removal of items
    if (collection.length == 0) {
      filteredArray = trackers?.filter(
        (list) =>
          list?.client_id == client?.id && list?.tracker_id == heading?.id
      );
      removedItemsArray = trackers.filter(
        (element) => !filteredArray.includes(element)
      );
      trackers = removedItemsArray;
    } else {
      // Removing All Matching Elements of a client and heading match data
      filteredArray = trackers?.filter(
        (list) =>
          list?.client_id == client?.id && list?.tracker_id == heading?.id
      );
      removedItemsArray = trackers.filter(
        (element) => !filteredArray.includes(element)
      );
      trackers = removedItemsArray;
      // Reinsert the values again
      collection.map((item) => {
        let findValue = trackers?.filter(
          (list) =>
            list?.client_id == client?.id &&
            list?.tracker_id == heading?.id &&
            list?.idea_id == parseInt(item?.value)
        );
        if (!findValue?.length) {
          trackers?.push({
            idea_id: item?.value,
            tracker_id: heading?.id,
            client_id: client?.id,
          });
        }
      });
    }

    trackerGridData["wow_tracker"] = trackers;
    // /** save client trcaker into mamories  */
    dispatch(saveTrackerData(trackerGridData));
    /** save action notification */
    dispatch(saveRequired(true));
  }

  function handleSubmitEvent(el) {
    el.preventDefault();

    dispatch(submitTrackerGridData(selectedListing?.value, {...trackerGridData}))
    /** save action notification */
    dispatch(saveRequired(false));
  }
};

export default Tracker;
