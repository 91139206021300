import React, {useState} from "react";
import { useDispatch, useSelector } from 'react-redux'
import Heading from './../../../components/layouts/heading';
import {usePrompt} from '../clientScoring/dirtyFormCheck'

import { saveActiveMetric, saveActiveMetricValues, saveClientMetricData, saveRequired } from "./../../../redux/actions/actionCreators";

const DemographicMetric = () => {
    const dispatch = useDispatch()

    const [active, setActive]  = useState('')

    const { metricHeading, clientMetricData, activeDemoMetric, activeMetricValues, selectedListing, constants } = useSelector((state) => state?.dataPack)
        usePrompt(constants?.POPUP_MODAL_MESSAGE,active)

    return (
        <>
            <Heading title={"Demographic Metrics"} date="13/02/2022"/>
            <div className="checkbox-table-wrapper demographic-metrics-table-wrapper table-wrapper">
                <div className="checkbox-table-head px-4 py-3">
                    <div className="add-new-wrapper d-flex align-items-center justify-content-end">
                        {
                            selectedListing?.value ?    
                            <button type="submit" className={`btn text-white m-0 ${active && 'notify'}`} onClick={(el) => handleSubmitEvent(el)}><i className="fa-solid fa-floppy-disks"></i> Save</button> :
                            <button type="submit" className="btn text-white m-0" disabled><i className="fa-solid fa-floppy-disks"></i> Save</button>
                        }
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-hover mb-0">
                        <thead>
                            <tr>
                                <th></th>
                                <th scope="col">Demographics</th>
                                {
                                    renderHeading()
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                renderMetricData()
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );

    function renderHeading()
    {
        return metricHeading?.map( (option, index) => {

            return (
                <th scope="col" key={index?.toString()}>{ option?.heading}</th>
            )
        })
    }

    function renderMetricData()
    {
        return clientMetricData?.map( (options, index) => {

            return (
                <tr key={index?.toString()}>
                    <td>{ index + 1 }</td>
                    <td>{ options?.metric_title }</td>
                    {
                        renderMetricDataValue(options)
                    }
                </tr>
            )
        })
    }

    function renderMetricDataValue(options)
    {
        return options?.metric_data?.map( (data, index)  => {

            let values  = (Array.isArray(activeMetricValues)) ? activeMetricValues[data?.metric_value_id] : ''

            return (
                <td scope="col" key={index?.toString()} className="cursor-pointer" onClick={() => dispatch(saveActiveMetric(data?.metric_value_id))} >
                    {
                        (activeDemoMetric == data?.metric_value_id) ?
                        <input type="text" className="form-control" onChange={(el) => handleInputChangeEvent(el, data)} autoFocus value={values} onBlur={() => dispatch(saveActiveMetric(''))}/>
                        :
                        ( values ? values : (data?.metric_value || <span>-</span>))
                    }
                    
                </td>
            )
        })
    }

    function handleInputChangeEvent(el, data)
    {
        let dataValues  = activeMetricValues
        dataValues[data?.metric_value_id] = el.target.value

        /** indicate changes */
        setActive(true)
        dispatch(saveRequired(true));
        /** storing all data changed value */
        dispatch(saveActiveMetricValues(dataValues))
    }

    function handleSubmitEvent(el)
    {
        const { DEMOGRAPHIC }   = constants?.METRIC_TYPE
        let metricData          = []

        if( activeMetricValues?.length )
        {
            activeMetricValues?.map((option, key) => {

                metricData?.push({
                    'metric_data_id' : key,
                    'metric_data_value' : option
                })
            })
        }
        /** indicate changes false */
        setActive('');
        dispatch(saveRequired(false));
        /** saving metric updated data */
        dispatch(saveClientMetricData(selectedListing?.value, metricData, DEMOGRAPHIC) )
    }
};

export default DemographicMetric;