import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
    deleteClient,
    saveActiveMetric,
    getClientScoreData,
    saveClientPsychoPoints,
    saveClientTotalPoints,
    saveRequired,
    getClientMetricData,
} from "./../../../redux/actions/actionCreators";
import { usePrompt } from "./dirtyFormCheck";

import SortingIconUp from '../../../components/assets/images/sorting-solid-iconUp.svg'
import SortingIconDown from '../../../components/assets/images/sorting-solid-iconDonwn.svg'

const DemographicScore = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const [sort, setSort] = useState("desc");

    const [clientData, setClientData] = useState();

    const {
        selectedListing,
        clients,
        constants,
        actionRequired,
        clientMetricData,
        activeDemoMetric,
        clientPsychoPoints,
        clientTotalPoints,
        helper,
        tiersList,
        selectedTier
    } = useSelector((state) => state?.dataPack);
    usePrompt(constants?.POPUP_MODAL_MESSAGE, actionRequired);

    useEffect(async () => {
        helper?.setTitle();
        /** get client saved metric data */
        await dispatch(
            getClientMetricData(
                selectedListing?.value,
                constants?.METRIC_TYPE?.DEMOGRAPHIC
            )
        );

        await dispatch(
            getClientScoreData(
                selectedListing?.value,
                constants?.METRIC_TYPE?.DEMOGRAPHIC
            )
        );
    }, dispatch);

    useEffect(() => {
        calculateTier(clients)
        setClientData(clients);
    }, [clients, clientTotalPoints]);

    useEffect(() => {
        if (clients?.length) sortDataByTier();
    }, [clients, clientTotalPoints]);

    function calculateTier(clients)
    {
        clients?.map((client) => {
            let tiersData = helper?.clientTierValue(
                tiersList,
                clientTotalPoints,
                client?.id
            );
            client["tier"] = tiersData?.clientScore;
            client["title"] =tiersData?.title
        })
    }
    
    return (
        <>
            {clientMetricData?.length ? (
                <>
                    <div className="table-responsive">
                        <table className="table table-hover mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Client Name</th>
                                    <th scope="col" colSpan="5">
                                        Demographics Metrics Values
                                    </th>
                                    <th scope="col">Score</th>
                                    <th
                                        scope="col"
                                        className="cursor-pointer"
                                        onClick={() => {
                                            setSort(_ =>
                                                sort == "asc" ? "desc" : "asc"
                                            )
                                            sortDataByTier(sort == "asc" ? "desc" : "asc")
                                        }}
                                    >
                                        Tier
                                        {
                                            sort == "asc" ? <img className="sorting-icon-down mx-2 align-middle" src={SortingIconDown} alt="sorting-icon" width= "20px" height= "20px"/> : <img className="sorting-icon-up mx-2 align-baseline" src={SortingIconUp} alt="sorting-icon" width= "20px" height= "20px"/>
                                        }
                                    </th>
                                </tr>
                                <tr>
                                    <th></th>
                                    {renderMetric()}
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>{renderClient()}</tbody>
                        </table>
                    </div>
                </>
            ) : (
                <div className="d-flex flex-wrap align-items-center justify-content-between px-4 py-3 bb-color">
                    <h4>No Metric data found!</h4>
                </div>
            )}
        </>
    );

    /**
     * Client metric list
     *
     * @returns object
     */
    function renderMetric() {
        return clientMetricData?.map((option, index) => {
            return (
                <>
                    <th scope="col" key={index?.toString()}>
                        {option?.metric_title}
                    </th>
                </>
            );
        });
    }
    /**
     *  rendering client score and update component
     * @returns
     */
    function renderClient() {
        
        let filterdClientData = (selectedTier && selectedTier?.value) ? clientData?.filter( list => list?.title == selectedTier?.label) : clientData
        return filterdClientData?.map((client) => {
            let tiersData = helper?.clientTierValue(
                tiersList,
                clientTotalPoints,
                client?.id
            );
            client["tier"] = tiersData?.clientScore;
            client["title"] = tiersData?.title
            
            return (
                <tr>
                    <td>
                        <span className="float-start ps-1">{client?.name}</span>{" "}
                        <button
                            className="bg-none border-0 dlt-client float-end"
                            type="button"
                            onClick={(el) => handleUserdeleteEvent(el, client)}
                        >
                            <i className="fa fa-trash"></i>
                        </button>
                    </td>
                    {renderMetricData(client)}
                    <td>{tiersData?.clientScore}</td>
                    <td>
                        {tiersData?.tierClass ? (
                            <button
                                className={`btn border-0 ${tiersData?.tierClass}`}
                                type="button"
                            >
                                {tiersData?.title}
                            </button>
                        ) : (
                            <button
                                className={`btn border-0 bronze-btn disabled`}
                                type="button"
                            >
                                -
                            </button>
                        )}
                    </td>
                </tr>
            );
        });
    }

    /**
     * Client metric list
     *
     * @returns object
     */
    function renderMetricData(client) {
        return clientMetricData?.map((option, index) => {
            let saltString = option?.metric_id + "-" + client?.id;

            let values =
                Array.isArray(clientTotalPoints) &&
                Array.isArray(clientTotalPoints[client?.id]) &&
                clientTotalPoints[client?.id][option?.metric_id]
                    ? clientTotalPoints[client?.id][option?.metric_id]
                    : "";

            return (
                <>
                    <td
                        key={index?.toString()}
                        onClick={() => dispatch(saveActiveMetric(saltString))}
                    >
                        {
                            <select
                                className="form-select custom-select-style no-padding"
                                onChange={(el) =>
                                    handleInputChangeEvent(el, option, client)
                                }
                                value={values}
                            >
                                <option value="0">0</option>
                                {renderMetricValue(option)}
                            </select>
                        }
                    </td>
                </>
            );
        });
    }

    function sortDataByTier(sort) {
        try {
            if (sort == "asc") clients?.sort((a, b) => a?.tier - b?.tier);
            else clients?.sort((a, b) => b?.tier - a?.tier);

            if (clients && clients?.length){ 
                setClientData(clients);
            }
        } catch (error) {}
    }

    /**
     * Client metric list
     *
     * @returns object
     */
    function renderMetricValue(option) {
        const { METRIC_DATA_VALUE } = constants;

        return METRIC_DATA_VALUE.map((element, index) => {
            return (
                <option key={element?.toString()} value={element}>
                    {element}
                    {option?.metric_data[index]?.metric_value
                        ? ` ( ${option?.metric_data[index]?.metric_value} )`
                        : null}
                </option>
            );
        });
    }

    /**
     * handle input change event
     *
     * @param {event} el
     * @param {object} option
     * @param {object} client
     *
     * @return
     */
    function handleInputChangeEvent(el, option, client) {
        let value = el.target.value;

        let dataValues = clientPsychoPoints;

        if (!dataValues[client?.id]) dataValues[client?.id] = [];

        dataValues[client?.id][option?.metric_id] = value;

        /** save action notification */
        dispatch(saveRequired(true));

        /** disabled editing */
        dispatch(saveActiveMetric(""));

        /** storing all data changed value */
        dispatch(saveClientPsychoPoints(dataValues));

        /** setting client total scores */
        dispatch(
            saveClientTotalPoints(
                helper?.getClientScore(
                    value,
                    client?.id,
                    option?.metric_id,
                    dataValues
                )
            )
        );
    }

    /**
     * handle user delete event
     *
     * @param {event} el
     * @param {*} client
     */
    function handleUserdeleteEvent(el, client) {
        el.preventDefault();

        /** trigger client delete event */
        dispatch(deleteClient(client?.id, selectedListing?.value));
    }
};

export default DemographicScore;
