import Route from "./routes";

/** bootstrap script */
import "bootstrap/dist/js/bootstrap";
/** bootstrap css */
import "bootstrap/dist/css/bootstrap.css";

/**
 * call parent route
 *
 * @param {*} NA
 *
 * @return {obejct} route
 */
const App = () => {
    return <Route />;
};
export default App;