import Heading from './../../../components/layouts/heading'
import { useSelector, useDispatch } from "react-redux";
import Service from './service'
import { saveValueGridData, warningMsg, saveRequired } from './../../../redux/actions/actionCreators'
import { saveRelationalGrid } from './../../../redux/actions/ListActions';
import { usePrompt } from '../clientScoring/dirtyFormCheck';

const ValueAddedGrid = (props) => {

    const dispatch = useDispatch()

    const { tiersList,constants,responsiblePersons, actionRequired, ideaList, valueGrid } = useSelector((state) => state?.dataPack)
    usePrompt(constants?.POPUP_MODAL_MESSAGE,actionRequired)

    const { scores } = valueGrid
    return (
        <>
            {
                <Heading title={"Relational Currency Service"} />
            }
            <div className="table-wrapper" id="value-grid" role="tabpanel" aria-labelledby="value-grid-tab">

                <div className="checkbox-table-wrapper">
                    <div className="checkbox-table-head d-flex flex-wrap align-items-center justify-content-center justify-content-sm-between px-4 py-3">
                        <h2></h2>
                        <div className="add-new-wrapper d-flex flex-wrap align-items-center justify-content-between">
                            <Service title={props?.title} type={props?.type} />
                            <button type="button" className={`btn text-white ${actionRequired && 'notify'}`} onClick={(el) => handleSubmitEvent(el)}><i className="fa-solid fa-floppy-disks"></i> Save</button>
                        </div>
                    </div>
                </div>
                <div className="value-grid-table-wrapper">
                    <div className="table-responsive">
                        <table className="table table-hover mb-0">
                            <thead>
                                <tr>
                                    <th scope="col">Services</th>
                                    {
                                        renderTiersList()
                                    }
                                    <th scope="col">Owner</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    renderServiceGrid(scores)
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
    
    /**
     * render tiers list
     * @param {*} NA
     * @returns 
     */
    function renderTiersList()
    {
        return tiersList?.map( (tier, index) => {
            return (
                <th scope="col" key={index?.toString()}>
                    <button className={`btn ${(tier?.tier_type)?.toLowerCase()}-btn border-0" type="button`}>
                        {tier?.tier_type}
                    </button>
                </th>
            )
        })
    }

    /**
     * render service grid list
     * 
     * @param {object} scores
     * @returns 
     */
    function renderServiceGrid(scores) {

        if (ideaList) {
            const { services, persons } = valueGrid

            return ideaList?.map((list, index) => {
                let defaultValue = (services && services[index]) ? services[index] : ''
                return (
                    <tr key={index?.toString()}>
                        <td>
                            <select className="form-select" aria-label="Bronze options" value={defaultValue} onChange={(el) => handleChangeEvent(el, index, defaultValue)}>
                                <option value="0">Select Service</option>
                                {
                                    renderServiceList()
                                }
                            </select>
                        </td>
                        {
                            renderTiersRow(list, scores, defaultValue)
                        }
                        <td>
                        <select  disabled={!defaultValue ?? false} className="form-select" aria-label="Bronze options" 
                        value={(defaultValue && persons && persons[defaultValue]) && persons[defaultValue]} 
                        onChange={(el) => handlePersonChangeEvent(el,index, defaultValue)}
                        >
                                <option value="0" >Select Responsible Person</option>
                                {
                                    renderResponsiblePersonList()
                                }
                        </select>
                        </td>
                    </tr>
                )
            })
        }
    }

    function renderResponsiblePersonList() {
        if (responsiblePersons) {
            return responsiblePersons?.map((value, index) => {
                return (
                    <option value={value?.id} key={index?.toString()}> {value?.name}</option>
                )
            })
        }
    }
    /**
     * render tiers list
     * 
     * @param {object} list 
     * @param {object} scores 
     * @param {int} defaultValue 
     * @returns 
     */
    function renderTiersRow(list, scores, defaultValue) {

        if (tiersList) {

            return tiersList?.map((tier, index) => {
                return (
                    <td key={index?.toString()}>
                        <select disabled={!defaultValue ?? false} className="form-select" aria-label="Platinum options" value={ defaultValue && scores && scores[defaultValue] && scores[defaultValue][tier?.id]} onChange={(el) => handleScoreChangeEvent(el, tier, defaultValue)}>
                            <option value="">Select</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </td>
                )
            })
        }
    }

    function renderServiceList() {
        if (ideaList) {
            return ideaList?.map((value, index) => {
                return (
                    <option value={value?.id} key={index?.toString()}> {value?.idea_title}</option>
                )
            })
        }
    }

    /**
     * handle service change event
     * 
     * @param {object} list 
     * 
     * @return 
    */
    function handleChangeEvent(el, index, defaultValue) {

        let value = el?.target?.value

        let dataValues = valueGrid['services'] ?? []
        let scoreValues = valueGrid['scores'] ?? []
        let personValues = valueGrid['persons'] ?? []

        /** if service already selected */
        if(el.target.value == 0){
            dataValues[index] = parseInt(value);
            valueGrid['services']   = dataValues;
            dispatch(saveValueGridData(valueGrid))
            return;
        }
        /** if service already selected */
        else if ( dataValues?.includes(parseInt(value)) ) {
            el.target.value = 0
            warningMsg('Service already selected.  Please select another service')
            return false
        }

        /** update score value in service */
        if(defaultValue)
        {
            let udpatedScores = []
            let updatedPerson = []

            let scoreId = parseInt(defaultValue);

            let scoreValue = scoreValues[scoreId]
            let personValue = personValues[scoreId]
            
            scoreValues.forEach((element, index) => {
                if( index != defaultValue )
                {
                    udpatedScores[index] = element 
                    updatedPerson[index] = personValues[index]
                }
            });
            
            /** update person value  */
            udpatedScores[parseInt(value)] = scoreValue
            updatedPerson[parseInt(value)] = personValue
            
            /** update score value */
            scoreValues = udpatedScores
            personValues = updatedPerson
        }
        
        /** initiate service grid*/
        dataValues[index] = parseInt(value)

        valueGrid['services']   = dataValues
        valueGrid['scores']     = scoreValues
        valueGrid['persons']    = personValues

        /** storing all data changed value */
        dispatch(saveValueGridData(valueGrid))
        /** save action notification */
        dispatch(saveRequired(true))
    }

    /**
     * handle service score change event
     * 
     * @param {event} el 
     * @param {object} list 
     * @param {object} tier 
     * 
     * @return 
    */
    function handleScoreChangeEvent(el, tier, defaultValue) {

        let value = el?.target?.value

        let dataValues = valueGrid['scores'] ?? []
        
        /** initiate array if not */
        if (!dataValues[defaultValue]) dataValues[defaultValue] = []
        /** updating value */
        dataValues[defaultValue][tier?.id] = value

        /** storing all data changed value */
        valueGrid['scores'] = dataValues
        dispatch(saveValueGridData(valueGrid))
        /** save action notification */
        dispatch(saveRequired(true))
    }

    function handlePersonChangeEvent(el, defaultValue) {

        let value = el?.target?.value
        // let dataValues = valueGrid['persons'] ?? []
        let dataValues = valueGrid['persons'] ?? []
        let scoreValues = valueGrid['scores'] ?? []
       
        /** updating value */
        dataValues[valueGrid['services'][defaultValue]] = value
     
        /** storing all data changed value */
        valueGrid['persons'] = dataValues
        valueGrid['scores'] = scoreValues
        /** save idea grid data */
        dispatch(saveValueGridData(valueGrid))
        /** save action notification */
        dispatch(saveRequired(true))
    }
    
    /**
     * handle submit event and pass data to action
     * 
     * @param {event} el 
     * @return 
     */
    async function handleSubmitEvent(el) {
        el.preventDefault()
        let gridValues = []
        let data = []
        let scoreValues = valueGrid['scores'] ?? []
        let serviceValues = valueGrid['services'] ?? []
        let personValues = valueGrid['persons'] ?? []
        if (serviceValues?.length) {
            serviceValues?.map((values) => {
                let scoreValue = scoreValues[values]
                if (scoreValue?.length) {
                    /** empty tier data */
                    data = []
                    /** adding tier data */
                    scoreValue?.map((value, key) => {
                        data?.push({
                            'tier_id': key,
                            'status': value
                        })
                    })
                    /** adding data array  */
                    gridValues?.push({
                        'idea_id': values,
                        'responsible_person_id': parseInt(personValues[values]) ?? '',
                        'tier': data
                    })
                }
                else {
                    /** empty tier data */
                    data = []
                    tiersList?.map((tier) => {
                        data?.push({
                            'tier_id': tier?.id,
                            'status': 0
                        })
                    });
                    /** adding data array  */
                    gridValues?.push({
                        'idea_id': values,
                        'responsible_person_id': parseInt(personValues[values]) ?? '',
                        'tier': data
                    })
                }
            })

            /** filter data array for empty any */
            let dataValues = gridValues?.filter(item => item.idea_id != 0);
            /** hit api to save the relation grid data */
            dispatch(saveRelationalGrid( dataValues, props?.type))
            /** save action notification */
            dispatch(saveRequired(false))
        }
        else
            warningMsg('No Changes Found.')
    }
}
export default ValueAddedGrid