import Filter from "./filter"
import moment from 'moment'
import { useSelector } from "react-redux";
import { constants } from "../../constants";
import { useState } from "react";


const Heading = ({ title, date, from, listing, callBack }) => {

    const { selectedListing } = useSelector((state) => state?.dataPack)

    const showFormatedDateWithTitle = (dateTitle) =>{
        let [first, ...rest] = dateTitle.split('-');
        let remainder = rest.join('-');
        // `${moment(responseData?.statement_date).format("MM/DD/YYYY")}${responseData?.statement_title ? " - " + responseData?.statement_title:''}`
        return `${moment(first).format('MM/DD/YYYY')}${remainder ? " - " + remainder:''}`;
    }
 
    return (
        <div className="tab-title-wrapper">
            <div className="col-md-12 d-flex justify-content-between text-right align-items-center ">
                <div>
                    <h1 className="tab-title"> {title ?? "Demographics"}</h1>
                    <small>{selectedListing?.label ? showFormatedDateWithTitle(selectedListing?.label) : ''}</small>
                </div>
       
               
            </div>
            {
                listing &&
                <div className="col-md-12">
                    <Filter />
                </div>
            }
        </div>
    )
}

export default Heading