import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import { confirm } from "react-confirm-box";
import Areas from "../../../modules/app/demographics/Areas";
import AddNew from "./AddNew";
import CopyOld from "./CopyOld";

import {
  deleteStatementListing,
  getStatementListing,
  defaultListItem,
} from "./../../../redux/actions/actionCreators";

/**
 * confirmation popup on click event handling
 *
 * @param {object} options
 * @param {object} params
 * @param {event} dispatch
 * @returns
 */
const onClick = async (options, params, dispatch) => {
  const result = await confirm(
    "Are you sure want to delete this item?",
    options
  );

  if (result) {
    dispatch(
      deleteStatementListing(
        params?.value,
        getStatementListing,
        defaultListItem
      )
    );
    return;
  }
};

const Filter = () => {
  /**
   * custom pagination
   *
   * @param {string} from
   * @param {string} to
   * @param {string} size
   * @returns
   */
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const dispatch = useDispatch();
  const { statementListing, selectedListing } = useSelector(
    (state) => state?.dataPack
  );



  return (
    <div className="demographics-date-filter d-flex align-items-center flex-column flex-lg-row pt-3">
      <Select
        options={options(statementListing)}
        className="form-select form-select-lg rounded-0"
        value={selectedListing}
        onChange={(el) => dispatch(defaultListItem(el))}
      />
      <div className="demographics-action-btns ps-md-4">
        <AddNew />

        {selectedListing?.value ? (
          <>
            <CopyOld />

            <button
              onClick={() => {
                onClick(optionsWithLabelChange, selectedListing, dispatch);
              }}
              type="button"
              className="btn btn-delete rounded-0 text-white my-0"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Delete"
            >
              <i className="fa-solid fa-trash-can"></i>
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              className="btn btn-copy rounded-0 text-white my-0"
              disabled
            >
              <i className="fa-solid fa-copy"></i>
            </button>
            <button
              type="button"
              className="btn btn-delete rounded-0 text-white my-0"
              title="Delete"
              disabled
            >
              <i className="fa-solid fa-trash-can"></i>
            </button>
          </>
        )}
      </div>
    </div>
  );

  function options(statementListing) {
    let optionData = [];
    try {
      statementListing?.map((option) => {
        optionData.push({
          label: `${moment(option?.statement_date).format("MM/DD/YYYY")}${option?.statement_title ? " - " + option?.statement_title:''}`,
          // label: `${moment(option?.statement_date).format("MM/DD/YYYY")}${option?.statement_title ?? ""}`,
          value: option?.id,
        });
      });
    } catch (error) {}
    return optionData;
  }
};

/**
 * confirmation popup box button text
 *
 * @param {*} NA
 * @return {*}
 */
const optionsWithLabelChange = {
  closeOnOverlayClick: false,
  labels: {
    confirmable: "Confirm",
    cancellable: "Cancel",
  },
};

export default Filter;
