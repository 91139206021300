import React from 'react';
import { Provider } from 'react-redux';

/** auth protected Middleware */
import Middleware from './Middleware';

/** auth protected Authentication */
import Authentication from '../modules/auth/authentication';

/** redux store*/
import store from './../redux/store'

/** Layout component */
import Layout from './../components/layouts'

/** global react ruter dom component */
import { BrowserRouter, Routes, Route } from 'react-router-dom'

/** Demographics page component */
import Demographics from './../modules/app/demographics'

/** Demographics Metrics page component */
import DemographicMetrics from './../modules/app/demographics/metric'

/** Psychographics page component */
import Psychographics from './../modules/app/psychographics'

/** Psychographics Metrics page component */
import PsychographicsMetrics from './../modules/app/psychographics/metric'

/** Tiers page component */
import Tiers from './../modules/app/tiers'

/** Admin Tiers page component */
import AdminTiers from './../modules/admin/tiers'

/** Client Scoring page component */
import ClientScoring from './../modules/app/clientScoring'

/** Demographics list page component */
import DemographicsList from './../modules/admin/demographics'

/** manage Statement Listing page component */
import Listing from './../modules/app/listing'

/** Relational Currency Service page component */
import ValueAddedGrid from './../modules/app/valueAddedGrid'

/** Relation Grid Service component */
import RelationGridService from './../modules/app/relationGridService'

/** Gap Analysis component */
import GapAnalysis from './../modules/app/gapAnalysis'

/** wow ideas */
import Ideas from '../modules/app/ideas';

/** Wow VIP Tracker component */
import WowTracker from './../modules/app/vipTracker'

/** NotFound component */
import NotFound from './../components/layouts/404'

/** Invalid Auth component */
import InvalidAuth from './../components/layouts/invalid'

/** Login component */

import ResponsiblePerson from '../modules/app/responsiblePerson';

/**
 * all routes
 *  
 * @param {*} NA
 * 
 * @return {obejct} route 
 */

const App = ( props ) => (  
    <Provider store = { store } >
        <BrowserRouter> 
            <Routes>
                <Route path="/authentication/:secretkey" element={<Authentication verifyAuth={true} {...props}/>} />
                <Route path="/404" element={<NotFound {...props}/>} />
                <Route path="/invalid/auth" element={<InvalidAuth {...props}/>} />    
            </Routes>
            <Middleware {...props} >
                <Layout {...props}>
                    <Routes>
                        <Route path="/" element={<Demographics {...props}/>} />
                        <Route path="/demographics" element={<Demographics {...props}/>} />
                        <Route path="/demographics/:add" element={<Middleware {...props}><Demographics {...props}/></Middleware>} />
                        <Route path="/demographic-metrics" element={<Middleware {...props}><DemographicMetrics {...props}/></Middleware>} />
                        <Route path="/psychographics" element={<Middleware {...props}><Psychographics {...props}/></Middleware>} />
                        <Route path="/psychographic/:add" element={<Middleware {...props}><Psychographics {...props}/></Middleware>} />
                        <Route path="/psychographic-metrics" element={<Middleware {...props}><PsychographicsMetrics {...props}/></Middleware>} />
                        <Route path="/admin/tiers" element={<Middleware {...props}><AdminTiers {...props} default={true} /></Middleware>} />
                        <Route path="/tiers" element={<Middleware {...props}><Tiers {...props} default={false} /></Middleware>} />
                        <Route path="/client-scoring/:type" element={<Middleware {...props}><ClientScoring {...props} /></Middleware>} />

                        <Route path="/manage/areas/list/:type" element={<Middleware {...props}><DemographicsList {...props}/></Middleware>} />
                        <Route path="/manage/areas/:add/:type" element={<Middleware {...props}><DemographicsList {...props}/></Middleware>} />
                        <Route path="/manage/areas/:update/:type" element={<Middleware {...props}><DemographicsList {...props}/></Middleware>} />

                        <Route path="/demographics/listing/:new" element={<Middleware {...props}><Demographics {...props}/></Middleware>} />
                        <Route path="/statement/add" element={<Middleware {...props}><Listing {...props}/></Middleware>} />
                        {/* Idea listing  Route */}
                        <Route path="/ideas" element={<Middleware {...props}><Ideas {...props}/></Middleware>} />
                        {/* Responsible Person Route */}
                        <Route path="/responsible-person" element={<Middleware {...props}><ResponsiblePerson {...props}/></Middleware>} />
                        <Route path="/value-added-grid" element={<Middleware {...props}><ValueAddedGrid {...props}/></Middleware>} />
                        <Route path="/relational-grid-service" element={<Middleware {...props}><RelationGridService {...props}/></Middleware>} />

                        <Route path="/gap-analysis" element={<Middleware {...props}><GapAnalysis {...props}/></Middleware>} />
                        <Route path="/vip-tracker" element={<Middleware {...props}><WowTracker {...props}/></Middleware>} />
                        
                    </Routes>
                </Layout>
            </Middleware>
        </BrowserRouter>
    </Provider >
);

export default App;