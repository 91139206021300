import React from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ManageDemographic from './manage'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { confirm } from "react-confirm-box";

import { deleteGlobalMetric, getGlobalMetric } from "./../../../redux/actions/actionCreators";
import { constants } from "../../../components/constants";
import Areas from "../../app/demographics/Areas";

/**
 * confirmation popup on click event handling
 * 
 * @param {object} options 
 * @param {object} params 
 * @param {event} dispatch 
 * @returns 
 */
const onClick = async (options, params, dispatch) => {
    const result = await confirm("Are you sure want to delete this item?", options);

    if (result) {
        dispatch(deleteGlobalMetric(params?.id, getGlobalMetric))
        return;
    }
};

/**
 * demographic componnet
 * 
 * @returns 
 */
const Demographics = () => {
    /** get url params */
    const params = useParams()
    const dispatch  = useDispatch()

    const { globalMetrics } = useSelector((state) => state?.dataPack)
    /** data filter by type */
    const filterdGlobalMetrics = globalMetrics?.filter( list => list?.metric_type == params?.type)

    /**
     * custom pagination
     * 
     * @param {string} from 
     * @param {string} to 
     * @param {string} size 
     * @returns 
     */
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    );
    /**
     * table columns name and field map 
     */
    const columns = [{
        dataField: 'title',
        text: 'Description'
    }, {
        dataField: 'Action',
        text: 'Action',
        formatter: buttonFormatter
    }];

    /**
     * table options
     */
    const options = {
        paginationSize: 4,
        pageStartIndex: 1,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '5', value: 5
        }, {
            text: '10', value: 10
        }, {
            text: 'All', value: filterdGlobalMetrics.length
        }]
    };

    /** metric area type */
    const areas  = constants?.METRIC_AREA_TYPE
 
    return (
        <>
            <div className="checkbox-table-wrapper table-wrapper">
                <div className="checkbox-table-head d-flex flex-wrap align-items-center justify-content-between p-4">
                    <h2> Manage {areas[params?.type]} Areas</h2>
                    <div className="add-new-wrapper d-flex align-items-center justify-content-between">
                        <Areas from="Admin" subTitle={areas[params?.type]} type={params?.type}/>
                    </div>
                </div>
                <div className="table-responsive">
                    <BootstrapTable keyField='id' data={filterdGlobalMetrics || []} columns={columns} pagination={paginationFactory(options)} classes="table table-hover" />
                </div>
            </div>
        </>
    );
    
    /**
     * table button customize
     * 
     * @param {object} row 
     * @param {object} col 
     * @returns 
     */
    function buttonFormatter(row, col) {
        return (
            <div>
                <Areas from="Admin" id={col?.id} oldValue={col?.title} subTitle={areas[params?.type]} type={params?.type}/>
                <button onClick={() => {
                    onClick(optionsWithLabelChange, col, dispatch);
                }}
                >
                    <i className="fa-solid fa-trash-can" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete"></i>
                </button>
            </div>
        )
    }
};
/**
 * confirmation popup box button text
 * 
 * @param {*} NA
 * @return {*}
 */
const optionsWithLabelChange = {
    closeOnOverlayClick: false,
    labels: {
        confirmable: "Confirm",
        cancellable: "Cancel"
    }
};



export default Demographics;