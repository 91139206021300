import Scoring from './scoring'
import { useParams } from "react-router";
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/** importing api actions functions */
import {getUserMetric, getStatementListing, defaultListItem, getClientMetricData, getTiers, getClientScoreData, getClients} from './../../../redux/actions/actionCreators'

const Index = () => {

    const { authUser, selectedListing, constants,helper } = useSelector((state) => state?.dataPack)

    const dispatch = useDispatch()
    const params = useParams()
    
    const tierUrl = `enabledTiers/${authUser?.user_id}`
  
    useEffect( async() => {
        
        helper?.setTitle()
        /** get all metrix data */
        dispatch(getUserMetric())

        /** get user tiers */
        dispatch(getTiers(tierUrl))

        /** get statement listing data */
        if( !selectedListing?.value ) 
            await dispatch(getStatementListing(defaultListItem))

        /** get all clients list */
        await dispatch(getClients(selectedListing?.value))

        /** get client saved metric data */

    }, dispatch)
    
    return (
        <Scoring />
    )
}

export default Index