import React,{useState,useRef,useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import {usePrompt} from '../clientScoring/dirtyFormCheck'

import { addGlobalMetric, addUserMetric, getGlobalMetric, getUserMetric, putGlobalMetric } from "./../../../redux/actions/actionCreators";

/** popup button and popover */
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const Areas = (props) => {
    const dispatch  = useDispatch()
    const closeRef = useRef();
    
    const inputRef = useRef();

    const { selectedListing,actionRequired,constants, authUser } = useSelector((state) => state?.dataPack) 

    const [title,setTitle] = useState(props?.oldValue ? props?.oldValue : '' );
    usePrompt(constants?.POPUP_MODAL_MESSAGE,actionRequired)

    useEffect(() => {
        setTitle(props?.oldValue);
    }, [props?.oldValue])
    

    const handleSubmitEvent =() =>
    {
        // If props.from exists the request will be considered as admin request
         if(props?.from){
            let data  = {
                'title': title,
                'metric_type' : props?.type
            }
            // If there is an id in the request then we can update the values else we will insert the value
            if( parseInt(props?.id) )
                dispatch(putGlobalMetric(data, props?.id, getGlobalMetric))
            else
                dispatch(addGlobalMetric(data, getGlobalMetric))
        }else{
        let data  = {
            'title': title,
            'metric_type' : props?.type,
            'user_id' : authUser?.user_id
        }
        /** add client function calling */
        dispatch(addUserMetric(data, getUserMetric))
    }
        setTitle('');
        closeRef.current.click();
    }

    return (
        <Popup dark onOpen={()=>inputRef.current.focus()}  
        trigger={props?.id ?
        <i className="fa-solid fa-pen-to-square" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit"></i>:
        <button type="button" className="btn button--save btn-default pull-right _mr10 text-white" ><i className="fas fa-plus ps-2"></i> Add New {props?.title}</button>
        } 
        modal>
            {close => (
                <>
                    <div className="modal show">
                        <button className="close" onClick={close} ref={closeRef}>
                            &times;
                        </button>
                        <div className="header text-left"> Add New {props?.title} </div>
                        <div className="content">
                            <div>
                                <label>{props?.subTitle} Description*</label>
                                <div className="form-group">
                                    <div className="form-group">
                                        <input ref={inputRef} required type="text" value={title} onChange={(e) => setTitle(e.target.value)} name="title" className="form-control" placeholder="Add description" maxLength="225"/>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="actions">
                        <button type="submit" onClick={()=>handleSubmitEvent()} form="form2" className="button"> Submit </button>
                        <button className="button"
                            onClick={() => {
                                close();
                            }}
                        >
                            close
                        </button>
                    </div>
                </>
            )}
        </Popup>
    );
}
 


export default Areas;