import React, {useState, useRef,useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";

import { saveIdea,saveResponsiblePerson,updateIdea, updateResponsiblePerson } from "./../../../redux/actions/actionCreators";

/** popup button and popover */
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const ResponsiblePersonService = (props) => {

    const dispatch = useDispatch()
    const inputRefs = useRef()
    const closeRef = useRef();

    const [title,setTitle] = useState(props?.oldValue);
   

    useEffect(() => {
        setTitle(props?.oldValue)
    }, [props?.oldValue])


    /**
     * add new client
     * 
     * @param {event} el 
     * @param
     */
    const handleSubmitEvent=()=>{
            let data  = {
                'name': title
            }
            if(parseInt(props?.id) ){
            /** Edit the owner name */
            dispatch(updateResponsiblePerson(data, props?.id))
            }
            else{
                /** add New owner to the list */
                dispatch(saveResponsiblePerson(data))
            }
            setTitle('');
            /** close modal */
            closeRef.current.click();
        }

 
    return (
        <Popup dark onOpen={()=>inputRefs.current.focus()} trigger={
            props.id?
            <i className="fa-solid fa-pen-to-square " data-bs-toggle="tooltip" data-bs-placement="bottom" title="Edit"></i>
            :
        <button className="btn button--save btn-success pull-right text-white px-3" ><i className="fas fa-plus"></i> Add New { props?.title}</button>
        } modal>
            {close => (<>
                    <div className="modal show">
                        <button className="close" onClick={close}>
                            &times;
                        </button>
                        <div className="header text-left"> {
                            props.id? "Edit"
                            : "Add"
                        } Owner </div>
                        
                        <div className="content">
                            <div>
                                <label>{props?.title} *</label>
                                <div className="form-group">
                                    <input ref={inputRefs} required type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} name="title" className="form-control" placeholder={`Add ${props?.title} here`} maxLength="225" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="actions">
                        <button onClick={()=>handleSubmitEvent()} className="button"> Submit </button>
                        <button ref={closeRef} className="button"
                            onClick={() => {
                                close();
                            }}
                        >
                            close
                        </button>
                    </div>
                    </>
            )}
        </Popup>
    );



 
};

export default ResponsiblePersonService;