export const constants = {
    METRIC_TYPE: { DEMOGRAPHIC: 1, PSYCHOGRAPHIC: 2},
    METRIC_AREA_TYPE: { 1 : 'Demographic', 2 : 'Psychographic'},
    MAX_AREA_SELECTION: 5,
    METRIC_DATA_VALUE: [1,2,3,4,5],
    ADMIN_ROLE_ID: 4,
    ADMIN_TIER: 'admin/tiers',
    WOW_IDEAS: { SERVICE: 1, MARKET: 2 } ,
    POPUP_MODAL_MESSAGE:'Are you sure you want to leave this page? Changes have not yet been saved!',
    TRACKER_FILTER: [{ value:1, label: 'Jan to March'}, {value:2, label: 'April to June'}, {value:3, label:'July to Sep'}, {value:4, label:'Oct to Dec' }] ,
    TRACKER_FILTER_VALUES: [{ value:1, range: [1,2,3]}, {value:2, range: [4,5,6]}, {value:3, range:[7,8,9]}, {value:4, range:[10,11,12] }] ,
    DEFAULT_PROFILE: "https://proadvisordrivers.com/assets/default-profile-icon.jpg"
}