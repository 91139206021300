import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";

/** popup button and popover */
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import {
  addNewStatementListing,
  getStatementListing,
  defaultListItem,
  copyOldStatementListingDate
} from "./../../../redux/actions/actionCreators";

const Areas = () => {
  const dispatch = useDispatch();
  const closeRef = useRef();
  const [selectedDate, setselectedDate] = useState('')
  const { statementListing,selectedListing } = useSelector(
    (state) => state?.dataPack
  );


  const copyStatementListingDate = (oldListingId) => {
    const data  = {
        'listing_id' : oldListingId,
        'new_statement_date' : moment(selectedDate).format("YYYY-MM-DD")
    }

    dispatch(copyOldStatementListingDate(
        data,
        moment(selectedDate).format("MM/DD/yyyy"),
        getStatementListing,
        defaultListItem
        )
    )
    /** close modal */
    closeRef.current.click();
  };

  function options(statementListing) {
    let optionData = [];

    try {
      statementListing?.map((option) => {
        optionData.push({
          label: moment(option?.statement_date).format("MM/DD/YYYY"),
          value: option?.id,
        });
      });
    } catch (error) {}
    return optionData;
  }

  return (
    <Popup
      dark
      trigger={
        <button
          type="button"
          className="btn btn-copy rounded-0 text-white my-0"
          data-bs-toggle="tooltip" data-bs-placement="bottom" title="Copy"
        >
          <i className="fa-solid fa-copy"></i>
        </button>
      }
      modal
    >
      {(close) => (
        <>
          <div className="modal show date-modal">
            <button className="close" onClick={close} ref={closeRef}>
              &times;
            </button>

            <div className="content">
              <div>
                <div className="form-group">
                  <div className="form-group">
                    <div>
                    <label >from where you want to copy? *</label>
                      <div className="form-group py-1">
                      <Select
                            options={options(statementListing)}
                            className="py-2 text-dark rounded-0"
                            value={selectedListing}
                            onChange={(el) => dispatch(defaultListItem(el))}
                        />
                      </div>
                      <label className="pt-2">Please select a Date*</label>
                      <div className="form-group">
                        <DatePicker
                        placeholderText='mm/dd/yyyy'
                          selected={selectedDate}
                          isOpen={true}
                          calendarClassName=""
                          className="form-control"
                          onChange={(date) => setselectedDate(date)}
                        />
                      </div>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="actions">
            <button
              type="submit"
              onClick={() => { copyStatementListingDate(selectedListing?.value) }}
              form="form2"
              className="button"
            >
              {" "}
              Submit{" "}
            </button>
            <button
              className="button"
              onClick={() => {
                close();
              }}
            >
              close
            </button>
          </div>
        </>
      )}
    </Popup>
  );
};

export default Areas;
