import axios from "axios";
/** api url */
const BASE_URL = process.env.REACT_APP_APP_API_URL;

/**
 * intercepts request at first and run the middleware function for reference see axios interceptors
 * 
 * @param {*} NA
 * @return
 */
axios.interceptors.request.use(function (config) {
    return config;
});

/**
 * call API
 * 
 * @param {string} url 
 * @param {object} data 
 * @returns 
 */
 export const authDataApi = async (url, data) => {
     
    const response = await axios.post(BASE_URL + url, data, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
    return response
};

/**
 * call API
 * 
 * @param {string} url 
 * @param {object} data 
 * @returns 
 */
export const postDataApi = async (url, data) => {

    if(!localStorage.getItem("passportToken")) return false
    const response = await axios.post(BASE_URL + url, data, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("passportToken")}`
        }
    });
    return response
};

/**
 * call GET API
 * 
 * @param {string} url 
 * @returns 
 */
 export const getDataApi = async (url) => {
    if(!localStorage.getItem("passportToken")) return false
    const response = await axios.get(BASE_URL + url, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("passportToken")}`
        }
    });
    return response
};

/**
 * call put API
 * 
 * @param {string} url 
 * @returns 
 */
 export const putDataApi = async (url, data) => {

    if(!localStorage.getItem("passportToken")) return false
    const response = await axios.put(BASE_URL + url, data, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("passportToken")}`
        }
    });
    return response
};

/**
 * call delete API
 * 
 * @param {string} url 
 * @returns 
 */
 export const delDataApi = async (url, data) => {
     
    if(!localStorage.getItem("passportToken")) return false
    const response = await axios.delete(BASE_URL + url, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("passportToken")}`
        }
    });
    return response
};
