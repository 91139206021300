import React, { useState ,useRef,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useParams } from "react-router";
import moment from "moment";

import { Link } from "react-router-dom";
import {
  addNewStatementListing,
  getStatementListing,
  defaultListItem,
} from "./../../../redux/actions/actionCreators";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
const Demographics = () => {
  
  const params = useParams();
  const inputRefs = useRef()
  const dispatch = useDispatch();

  const { globalMetrics } = useSelector((state) => state?.dataPack);
  const [selectedDate, setselectedDate] = useState(new Date());
  const [redirect, setRedirect] = useState(0);
  const [postion,setPosition]=useState(0)

useEffect(() => {
  inputRefs.current.setFocus()
  window.scrollTo(0,50)
  return () => {
    window.scrollTo(0,0)
  }
}, [])


  
  return (
    <div className="vMapContainer">
      <form onSubmit={(el) => handleSubmitEvent(el)} method="post">
        {redirect && <Navigate to={`/demographics`} />}
        <div className="item-detail__container show">
          <div className="item-detail">
            <div className="item-detail__header">
              <Link
                to={`/demographics`}
                className="btn button--save btn-default pull-right _mr10"
              >
                <i className="fa fa-times"></i> Cancel
              </Link>
              <button className="btn button--save btn-success pull-right _mr10">
                <i className="fa-solid fa-share-from-square"></i> Save & Close
              </button>
              <div className="clearfix"></div>
            </div>
            <div className="row">
              <div className="item-detail__heading col-xs-12 col-sm-6">
                <div>
                  <label>Please Select Date*</label>
                  <div className="form-group">
                    <DatePicker 
                      selected={selectedDate} isOpen={true}
                      calendarClassName=""
                      className="form-control" ref={inputRefs}
                      onChange={(date) => setselectedDate(date)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );

  function handleSubmitEvent(el) {
    el.preventDefault();
    // let data = {
    //   statement_date: moment(selectedDate).format('YYYY-MM-DD')
    // };

    let formData  = new FormData;
    formData.append('statement_date', moment(selectedDate).format('YYYY-MM-DD'));
    /** dispatch event  ne wlisting */
    dispatch(
      addNewStatementListing(formData, moment(selectedDate).format("MM/DD/yyyy") ,getStatementListing, defaultListItem)
    );
    /** redirect to main page */
    setRedirect(true);
  }
};

export default Demographics;
