import Tiers from './tiers'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {getTiers} from './../../../redux/actions/actionCreators'

const Index = ( props ) => {

    const dispatch = useDispatch()

    const { authUser,helper } = useSelector((state) => state?.dataPack)

    const tierUrl = `tiers/${authUser?.user_id}`

    useEffect( () => {
        helper?.setTitle()
        /** get all metrix data */
        dispatch(getTiers(tierUrl))

    }, dispatch)
    
    return (
        <Tiers {...props} url={tierUrl}/>
    )
}

export default Index