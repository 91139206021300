import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams} from "react-router-dom";
import Loading from '../../components/layouts/invalid/Loading';
import {emptyReduxVariables, postLogin, removeAccessToken} from './../../redux/actions/actionCreators'

function Authentication (){
    
    const { authUser } = useSelector((state) => state?.dataPack)
  
    const navigate  = useNavigate();
    const dispatch  = useDispatch();
    const params    = useParams();
    let secreteKey  = params?.secretkey 
 
    useEffect( async() => {
        dispatch(emptyReduxVariables()).then(()=>{
            /** remove existing token to prevent the user simulate */
            dispatch(removeAccessToken());
        }).catch(err=>{
            console.log("Got Error",err)
        })
        
        const formData  = new FormData()
        formData.append('secret_token', secreteKey);
        
        /**  access token verify and get */
        await dispatch(postLogin(formData, secreteKey));

    }, [dispatch]);

    return (
        <>
        {
            (authUser?.status)
            ? 
            navigate('/')
            :
            (authUser && !authUser?.status) ?
            null
            :
            <Loading />
        }
         </>
    )
}

export default Authentication