import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";

/** popup button and popover */
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import {
    addNewStatementListing,
    getStatementListing,
    defaultListItem,
  } from "./../../../redux/actions/actionCreators";

const Areas = () => {

    const dispatch  = useDispatch()
    const closeRef = useRef();
    const [selectedDate, setselectedDate] = useState(new Date());
    const [title, setTitle] = useState('');
    const [show, setShow] = useState(false);
 
    function handleSubmitEvent() {
        // Return if there is no title
        if(title == ''){
          setShow(true);
          return;
        }

        let data = {
          statement_date: moment(selectedDate).format("YYYY-MM-DD"),
          statement_title:title
        };

        /** dispatch event  new listing */
        dispatch(
          addNewStatementListing(
            data,
            `${moment(selectedDate).format("MM/DD/yyyy")} - ${data.statement_title}`,
            getStatementListing,
            defaultListItem
          )
        );

        setselectedDate(new Date());

        /** close modal */
        closeRef.current.click();

      }

    return (
        <Popup
        dark
        trigger={
          <button
            type="button"
            className="btn btn-new border-0 rounded-0 text-white my-0"
            data-bs-toggle="tooltip" data-bs-placement="bottom" title="New"
          >
              <i className="fa-solid fa-circle-plus"></i>
        
          </button>
        }
        modal
      >
        {(close) => (
          <>
            <div className="modal show date-modal">
              <button className="close" onClick={()=>{close();setTitle('')}} ref={closeRef}>
                &times;
              </button>
          
              <div className="content">
                <div>
                  <div className="form-group">
                    <div className="form-group">
                      <div>
                        <label className="mb-2" >Statement Title :</label>
                        <small className={`${show?'d-block':'d-none'} text-danger`}>Title is required</small>
                        <div>
                        <input maxLength={100} type="text" value={title} onChange={(e) => {
                          setTitle(e.target.value);
                          if(show) setShow(false)
                          }}  placeholder="Enter Title" name="title" className="form-control" />
                        </div>
                        <label className="mt-3">Select the Date :</label>
                        <div className="form-group">
                          <DatePicker
                            selected={selectedDate}
                            isOpen={true}
                            calendarClassName=""
                            className="form-control"
                            onChange={(date) => setselectedDate(date)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="actions">
              <button
                type="submit"
                onClick={() => handleSubmitEvent()}
                form="form2"
                className="button"
              >
                {" "}
                Submit{" "}
              </button>
              <button
                className="button"
                onClick={() => {
                  close();
                }}
              >
                close
              </button>
            </div>
          </>
        )}
      </Popup>
    );
}
 


export default Areas;