import React, { useState,useEffect } from "react";
import { useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import Heading from './../../../components/layouts/heading'
import { deleteUserMetric, getUserMetric,saveRequired, warningMsg, saveUserMetric, findStatementListing, markSavedItems} from "./../../../redux/actions/actionCreators";

import { Link } from "react-router-dom";
import AddListing from './../listing/add'
import AddNew from './addNew'
import Areas from './Areas'
import { confirm } from "react-confirm-box";

/**
 * confirmation popup on click event handling
 * 
 * @param {object} options 
 * @param {object} params 
 * @param {event} dispatch 
 * @returns 
 */
const onClick = async (options, params, dispatch) => {
    const result = await confirm("Are you sure want to delete this item?", options);

    if (result) {
        dispatch(deleteUserMetric(params?.id, getUserMetric))
        return;
    }
};

const Demographics = () => {

    const params = useParams()
    const [selected,setSelected]=useState([]);
    const dispatch  = useDispatch()
    const { globalMetrics, constants, actionRequired,statementListing,selectedListing, selectedAreaItems } = useSelector((state) => state?.dataPack)
    const filterdGlobalMetrics = globalMetrics?.filter(list => list?.metric_type == constants?.METRIC_TYPE?.DEMOGRAPHIC);

    useEffect(() => {
        let filtered = filterdGlobalMetrics?.filter(item => selectedAreaItems.includes(String(item?.id))).map(function(item) {
            return  parseInt(item.id);
        });
     
        setSelected(filtered);
    }, [selectedAreaItems])
    
    
    return (
        <>
            <Heading title={"Demographics"} listing={true}/>
            <div className="checkbox-table-wrapper position-relative table-wrapper ">
            <div className={`${statementListing.length?'':"disabled_table"}`} />
            
                <form onSubmit={(el) => handleSubmitEvent(el)} >
                    
                <div className="checkbox-table-head d-flex  flex-wrap align-items-center justify-content-between p-4">
             
                    <h2>Check Your Top 5 Demographic Areas for Ideal Co-Creation</h2>
                    <div className="add-new-wrapper d-flex align-items-center justify-content-between">
                    <Areas title="Demographic" subTitle="Demographic" type={constants?.METRIC_TYPE?.DEMOGRAPHIC}/>
                            <button type="submit"  className={`btn text-white ${actionRequired && 'notify'}`} ><i className="fa-solid fa-floppy-disks"></i> Save</button> 
                    </div>
                </div>
                    <div className="table-responsive">
                        <table className="table table-hover mb-0">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th scope="col">Title</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    renderMetricAreas()
                                }
                            </tbody>
                        </table>
                    </div>
                 
                </form>
            </div>
            {
                params?.new &&
                <AddListing />
            }
        </>
    );
    function renderMetricAreas() {
     

        return filterdGlobalMetrics?.map(option => {

           let isSelected  = selectedAreaItems?.length ? selectedAreaItems.filter(list => list == option?.id  ) : ''
            
            return (
                <tr className={isSelected?.length && 'checked-row'} key={option?.id}>
                    <td><input className="form-check-input" 
                    disabled={isSelected?.length > 0 ? false : selected.length < 5 ? false : true} 
                    type="checkbox" value={option?.id} 
                    aria-label="Checkbox for selecting" checked={(isSelected?.length > 0) ? true : false} onChange={(el) => manageSelectedData(el)} /></td>
                    <td className="areas">{ option?.title }</td>
                    <td className="drag-row">
                        
                        <div>
                            {
                                (isSelected?.length > 0 && option?.user_id) &&
                                <button className="disabled" disabled><i className="fa-solid fa-trash-can" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete"></i></button>
                            }
                        </div>
                            {
                                (isSelected?.length == 0 && option?.user_id) &&
                                <div><button type="button" onClick={(el) => onClick(optionsWithLabelChange, option, dispatch)}><i className="fa-solid fa-trash-can" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete"></i></button></div>
                            } 
                    </td>
                </tr>
            )
        })
    }
    
    function manageSelectedData(el){
       
        let value  = el.target.value
        let data   = selectedAreaItems

        if( selected?.includes(parseInt(value)) ){
        let filteredData  = selected?.filter(list => list != parseInt(value))
        setSelected(filteredData);
        }
        else{
            let filteredData = [...selected,parseInt(value)];
            setSelected(filteredData); 
        }
    
        if( data?.includes(value) )
            data  = data?.filter(list => list != value)
        else
        data?.push(value)

        /** save action notification */
        dispatch(saveRequired(true))
        dispatch(markSavedItems(data))
    }
    /**
     * handle item selected event
     * 
     * @param {object} el 
     * @return 
     */
    function handleSubmitEvent(el)
    {
        el.preventDefault()

        let checkedItems = document.querySelectorAll('.form-check-input:checked')

        if( !selectedListing?.value )
        {
            warningMsg('Please select statement listing to proceed!', 'Warning')
            return false
        }
        if( checkedItems?.length != constants?.MAX_AREA_SELECTION )
        {
            warningMsg('The metric must have '+ constants?.MAX_AREA_SELECTION +' items.', 'Warning')
            return false
        }

        /** fet data serialise */
        let data  = {
            'listing_id': selectedListing?.value,
            'metric_type' : constants?.METRIC_TYPE?.DEMOGRAPHIC,
            'metric_id' : getSelectedItems(checkedItems)
        }

        /** dispatch event to save user selected items */
        dispatch(saveUserMetric(data, findStatementListing))

    }
    /**
     * find all selected items
     * 
     * @param {object} checkedItems 
     * @returns {array} data
     */
    function getSelectedItems(checkedItems)
    {
        let data = []

        checkedItems?.forEach(element => {
            data.push(element?.value)
        });
        return data
    }

    /**
     * handle item delete event
     * 
     * @param {object} el 
     * @return 
     */
    function handleItemDeleteEvent(el)
    {
        el.preventDefault()
    }
};

/**
 * confirmation popup box button text
 * 
 * @param {*} NA
 * @return {*}
 */
const optionsWithLabelChange = {
    closeOnOverlayClick: false,
    labels: {
        confirmable: "Confirm",
        cancellable: "Cancel"
    }
};

export default Demographics;