import PsychographicMetric from './psychographicsMetrics'

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'


import {geMetricHeadingData, getClientMetricData, recentStatementListing} from './../../../redux/actions/actionCreators'

const Metric = () => {

    const dispatch = useDispatch()

    const { selectedListing, constants,helper } = useSelector((state) => state?.dataPack)

    const { PSYCHOGRAPHIC }  = constants?.METRIC_TYPE

    useEffect( async() => {
        helper?.setTitle();
        /** get statement listing data */
        if( !selectedListing )
            await dispatch(recentStatementListing(getClientMetricData, PSYCHOGRAPHIC))
        else
            await dispatch(getClientMetricData(selectedListing?.value, PSYCHOGRAPHIC))

        /** get all metrix data */
        await dispatch(geMetricHeadingData(PSYCHOGRAPHIC))
        
    }, dispatch)
    
    return (
        <PsychographicMetric />
    )
}

export default Metric