import React from "react";

export default function Loading() {
  return (
    <div className="justify-content-center align-items-center d-flex vh-100">
      <div className="row">
        <div className="d-block  text-center">
          <div class="spinner-grow text-secondary me-3" role="status" />
          <div class="spinner-grow text-secondary me-3" role="status" />
          <div class="spinner-grow text-secondary me-3" role="status" />
          <div class="spinner-grow text-secondary me-3" role="status" />
          <div class="spinner-grow text-secondary me-3" role="status" />
          <div class="spinner-grow text-secondary me-3" role="status" />
        </div>
        <div className=" text-center mt-3">
          <h5>LOADING WOW</h5>
        </div>
      </div>
    </div>
  );
}
