import Header from './header'
import Footer from './footer'
import Loader from './../loader'

import { useSelector } from "react-redux";

import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

const Layout = (props) => {
    
    const { authUser, processing} = useSelector((state) => state?.dataPack)
    
    return (
        <>
        {
            authUser?.status ?
            <section>
                <ReactNotifications />
                <Header />
                <main className="d-flex flex-column">
                    <div className="container wow-app px-0"> 
                        <div className="tab-content">
                            {
                                props.children
                            }
                        </div>
                    </div>
                </main>
                <Footer />
                {
                    processing &&
                    <Loader />
                }
            </section>
            :
            props.children
        }
        </>
    )
}

export default Layout