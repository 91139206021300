import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const Error = ({ title, date }) => {
  const BASE_URL = process.env.REACT_APP_APP_URL;

  const [time, setTime] = useState(10);
  //Redirecting User back to proadvisordrivers.com after 10 seconds
  useEffect(() => {
    let interval;
    if (time > 0) {
      interval = setTimeout(() => {
            setTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      window.location.replace(BASE_URL);
    }
    return () => clearInterval(interval);
  }, [time]);

  return (
    <div className="vh-100 overflow-hidden notfound-page">
      <div className="wrapper">
        <h1 className="invalid-auth-title">Invalid Authentication!</h1>
        <h5>Sorry, Invalid Auth Attempted</h5>
        <p>
          Which page you are looking for might have been removed had its name
          changed or is temporarily unavailable
        </p>
        <p>You will be redirected back to proAdvisorDriver in {time} seconds</p>
        <div className="buttons">
          <a href={BASE_URL}>
            <i className="fas fa-home"></i> Go to Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default Error;
