import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { constants } from '../../../components/constants';
import Heading from '../../../components/layouts/heading'
import { getIdea } from '../../../redux/actions/actionCreators';
import { deleteIdea, deleteResponsiblePerson, getResponsiblePersonData } from '../../../redux/actions/ListActions';
import Service from "../valueAddedGrid/service";
import { Link } from "react-router-dom";
import ResponsiblePersonService from './service';

const ResponsiblePerson = () => {

    const dispatch = useDispatch();
    const [type, setType] = useState(constants.WOW_IDEAS.SERVICE);
    const [typeName,setTypeTitle]=useState("Service");
    const { responsiblePersons,helper } = useSelector((state) => state?.dataPack);
    const [selected, setSelected] = useState(constants.WOW_IDEAS.SERVICE);

    useEffect(() => {
        helper?.setTitle();
        dispatch(getResponsiblePersonData());
        setTypeTitle((type==constants.WOW_IDEAS.MARKET)?"Market":"Service");
    }, [type])
 
    const removeOwner = (id) => {
        let filteredData = responsiblePersons?.filter(list => list?.id != id);
        dispatch(deleteResponsiblePerson(id, filteredData));
    }

    return (
        <>
           <Heading title={"Owners"}   />
            <div className="ideas-table-wrapper table-wrapper">
                <div className="checkbox-table-wrapper">
                    <div className="checkbox-table-head d-flex flex-wrap align-items-center justify-content-center justify-content-sm-between px-4 py-3 bb-color">
                        <h2></h2>
                        <div className="add-new-wrapper d-flex flex-wrap align-items-center justify-content-between">
                        <ResponsiblePersonService title={"Owner Name"}  />
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-hover mb-0 align-items-center">
                        <thead>
                            <tr>
                                <th className='text-center'>Id</th>
                                <th>Owners</th>
                                <th className='d-flex me-4 justify-content-end'>
                                    <span className='me-5'>Actions</span>
                                    </th>
                            </tr>
                        </thead>
                        <tbody >
                            {responsiblePersons?.map((item, index) => {
                                return (<tr key={index}>
                                    <td className='text-center ps-0'>{index + 1}</td>
                                    <td>{item.name}</td>
                                    <td className='d-flex justify-content-end'>
                                        <span className='me-5'>
                                        <ResponsiblePersonService title={"Owner Name"} id={item?.id} oldValue={item?.name}  />
                                        <button type="button" className='btn btn-delete ms-4'  onClick={() => { removeOwner(item.id) }}
                                            data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete"><i className="fa-solid fa-trash-can"></i>
                                        </button>
                                        </span>
                                    </td>
                                </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default ResponsiblePerson