import React, { useEffect } from 'react'
import ValueAddedGrid from './valueAddedGrid'
import { useDispatch, useSelector } from 'react-redux'

/** importing api actions functions */
import {getIdea, getTiers} from './../../../redux/actions/actionCreators'

import { getRelationalGrid, getResponsiblePersonData } from './../../../redux/actions/ListActions'

const Index = () => {

    const { authUser, constants, helper } = useSelector((state) => state?.dataPack)

    const tierUrl = `enabledTiers/${authUser?.sub}`

    const dispatch = useDispatch()
    
    useEffect( async() => {
        helper?.setTitle()
        /** get all metrix data */
        dispatch(getTiers(tierUrl))

        /** get all wow service idea list */
       dispatch(getIdea(constants?.WOW_IDEAS?.SERVICE))

       /** get saved data */
       dispatch(getRelationalGrid(constants?.WOW_IDEAS?.SERVICE))

        /** get all Responsible Persons */
        await dispatch(getResponsiblePersonData())

    }, dispatch)
    
    return (
        <ValueAddedGrid type={constants?.WOW_IDEAS?.SERVICE} title="Service"/>
    )
}

export default Index