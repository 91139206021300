import Add from './add'

const List = () => {

    return (
        <div className="vMapContainer">
            <Add />
        </div>
    );

    
};

export default List;