import React, {useState,useRef} from "react";
import { useSelector, useDispatch } from "react-redux";

import { addClients } from "./../../../redux/actions/actionCreators";

/** popup button and popover */
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const Client = () => {

    const dispatch = useDispatch()
    const inputRefs = useRef()
    const { selectedListing } = useSelector((state) => state?.dataPack)
    const [name, setName] = useState('')

    return (

        <Popup dark onOpen={()=>{inputRefs.current.focus()}} trigger={<button className="btn button--save btn-default pull-right text-white px-3" ><i className="fas fa-plus"></i> Add New Client</button>} modal>
            {close => (
                <form onSubmit={(el) => { handleClientAddEvent(el, close)}}>
                    <div className="modal show">
                        <button className="close" onClick={close}>
                            &times;
                        </button>
                        <div className="header text-left"> Add New Client </div>
                        <div className="content">
                            <div>
                                <label>Client Name*</label>
                                <div className="form-group">
                                    <input required="" type="text" id="title" name="title"  className="form-control" placeholder="Add client name here" maxlength="225" onChange={(el) => setName(el.target.value)} value={name} ref={inputRefs}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="actions">
                        <button className="button"> Submit </button>
                        <button className="button"
                            onClick={() => {
                                close();
                            }}
                        >
                            close
                        </button>
                    </div>
                </form>
            )}
        </Popup>
    );

    /**
     * add new client
     * 
     * @param {event} el 
     * @param
     */
    function handleClientAddEvent(el, close)
    {
        el.preventDefault()

        let data  = {
            'name': name,
            'listing_id': selectedListing?.value
        }
        /** add client function calling */
        dispatch(addClients(data))
        setName('')
        /** close modal */
        close();
    }
};

export default Client;