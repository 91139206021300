import Demographics from './demographics'

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'


import {getUserMetric, getStatementListing, defaultListItem, findStatementListing, processing} from './../../../redux/actions/actionCreators'

const Index = () => {

    const { selectedListing,helper } = useSelector((state) => state?.dataPack)

    const dispatch = useDispatch()

    useEffect( async() => {
        helper?.setTitle()
        /** get all metrix data */
        await dispatch(getUserMetric())

        /** get statement listing data */
        await dispatch(getStatementListing(defaultListItem))

        /** find all selected option */
        await dispatch(findStatementListing(selectedListing?.value))
        
        processing(dispatch,false)
    }, dispatch)
    
    return (
        <Demographics />
    )
}

export default Index