import React, { Component } from "react";

export default class Index extends Component
{   
    /**
     *  Set Page Title
     */
    setTitle() {
        let path = ((window.location.pathname.substring(1)).replace("/", "-"));
        if(path.length){
            let camelCasePath = '';
            path.split('-').map(item=>{
                let upperCaseWord= item.charAt(0).toUpperCase() + item.slice(1);
                camelCasePath = `${camelCasePath} ${upperCaseWord}` ;
            })
            document.title = `WOW | ${camelCasePath}`;
        }else{
            document.title = `WOW | Demographics`;
        }
    }

    /**
     *  calculate client score
     * 
     * @param {object} value 
     * @param {string} data 
     * @param {object} clientTotalPoints 
     * 
     * @returns {int} totalPoints
     */
    getClientScore(value, clientId, metricId, clientTotalPoints)
    {
        let totalPoints  = clientTotalPoints
        
        totalPoints[clientId][metricId] = parseInt(value)
        
        return totalPoints
    } 

    // Function to check nearest values
    checkNearest(score,valueArray) {
        let temp = Math.abs(valueArray[0] - score);
        let closest = 0;
        if (valueArray[0] > score) return valueArray[0];
        if (valueArray[valueArray.length - 1] < score) return valueArray[valueArray.length - 1];
        valueArray?.map((value,key)=>{
            if (Math.abs(valueArray[key] - score) < temp) {
                temp = Math.abs(valueArray[key] - score);
                closest = valueArray[key];
            }
        })
        return closest;
    }

    /**
     * calculate client tiers
     * 
     * @param {object} tiersList 
     * @param {object} clientScore 
     * 
     * @return {string} tierClass
     */
    clientTierValue(tiersList, clientTotalPoints, clientId)
    {
        // Variable to handle tier Values
        let tierType = null;
        let tierArray = [];
        let clientScore = this.sumScore(clientTotalPoints, clientId)

        tiersList?.map(value=>{
            if((value['min_value'] <= clientScore) && (clientScore <= value['max_value'])){
                tierType = value['tier_type'];
               
            }
            tierArray?.push(value['min_value'],value['max_value']);
        });

        if(tierType){
            let tierClass  = (tierType || '').toLowerCase() + '-btn'
            return {tierClass: tierClass, title: tierType, clientScore:clientScore }
        }else{
            tierArray.sort();
            let closest = this.checkNearest(clientScore,tierArray);
            tiersList?.map(value=>{
                if((value['min_value'] == closest) || (closest == value['max_value'])){
                    tierType = value['tier_type'];
                }
            })
            let tierClass  = (tierType || '').toLowerCase() + '-btn'
            return {tierClass: tierClass, title: `~ ${tierType}`, clientScore:clientScore }
        }
    }

    sumScore(clientTotalPoints, clientId)
    {
        let score = 0

        clientTotalPoints?.forEach( (values, index) => {

            if( clientId == index )
            {
                values?.map( value => {
                    score   += parseInt(value)
                })
            }
        })

        return score
    }
}