import List from './list'

import React, { useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux'


import {getGlobalMetric} from './../../../redux/actions/actionCreators'

const Index = () => {
    
    const { helper} = useSelector((state) => state?.dataPack)
    const dispatch = useDispatch()

    useEffect( () => {
        helper?.setTitle()
        dispatch(getGlobalMetric())

    }, dispatch)
    
    return (
        <List />
    )
}

export default Index