import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate ,useParams} from "react-router-dom";

import {verifyAuthToken} from './../redux/actions/actionCreators'

function Middleware (props){
    
    const dispatch      = useDispatch();
    const navigate      = useNavigate();
    const params    = useParams();
    let secreteKey  = params?.secretkey ?params?.secretkey  : localStorage.getItem('secreteKey') || null
    const { authUser, userProfile } = useSelector((state) => state?.dataPack)

    useEffect( async() => {

        const formData  = new FormData()
        formData.append('secret_token', secreteKey);
        formData.append('user_id', authUser?.user_id);
        console.log(params?.secretkey)
        /**  access token verify and get */
        if( secreteKey ){
           await dispatch(verifyAuthToken(formData, userProfile, navigate));
        }
    }, [dispatch]);
   
    return (
        <>
            {
                authUser?.status?
                props?.children
                :
                (authUser && !authUser?.status) &&
                navigate('/invalid/auth')
            
            }
        </>
    )
}

export default Middleware