import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { constants } from '../../../components/constants';
import Heading from '../../../components/layouts/heading'
import { getIdea } from '../../../redux/actions/actionCreators';
import { deleteIdea } from '../../../redux/actions/ListActions';
import Service from "../valueAddedGrid/service";
import { confirm } from "react-confirm-box";

const Ideas = () => {

    const dispatch = useDispatch();
    const [type, setType] = useState(constants.WOW_IDEAS.SERVICE);
    const [typeName,setTypeTitle]=useState("Service");
    const { ideaList,helper,authUser } = useSelector((state) => state?.dataPack);
    const [selected, setSelected] = useState(constants.WOW_IDEAS.SERVICE);
  

    useEffect(() => {
        helper?.setTitle()
        dispatch(getIdea(type));
        setTypeTitle((type==constants.WOW_IDEAS.MARKET)?"Market":"Service");
    }, [type])

    /**
     * confirmation popup on click event handling
     * 
     * @param {object} options 
     * @param {object} params 
     * @param {event} dispatch 
     * @returns 
     */
    const onClick = async (options, id, dispatch) => {
        const result = await confirm("Are you sure want to delete this item?", options);

        if (result) {
            let filteredData = ideaList?.filter(list => list?.id != id);
            dispatch(deleteIdea(id, filteredData));
            return;
        }
    };

    /**
     * confirmation popup box button text
     * 
     * @param {*} NA
     * @return {*}
     */
    const optionsWithLabelChange = {
        closeOnOverlayClick: false,
        labels: {
            confirmable: "Confirm",
            cancellable: "Cancel"
        }
    };

    // Function to execute when button is changed in ideas tab;
    const checkIdeasIconChange = (event) => {
        setSelected(event.target.value);
        setType(event.target.value);
    }

    return (
        <>
           <Heading title={"All Ideas"}   />
            <div className="ideas-table-wrapper table-wrapper">
                <div className="checkbox-table-wrapper">
                    <div className="checkbox-table-head d-flex flex-wrap align-items-center justify-content-center justify-content-sm-between px-4 py-3 bb-color">
                        <h2></h2>
                        <div className="add-new-wrapper d-flex flex-wrap align-items-center justify-content-between">
                        <Service title={"Wow Idea"} type={type} />
                        <div >
                    <div className="btn px-0 py-0" >

                        <label className={`btn ${(selected == constants.WOW_IDEAS.SERVICE)?"btn-secondary":"btn-secondary"}  'active' `}>
                            <input type="radio" name="options" checked={selected == constants.WOW_IDEAS.SERVICE} value={constants.WOW_IDEAS.SERVICE} onChange={(el) => checkIdeasIconChange(el)} id="option1" autoComplete="off" /> Services
                        </label>
                        <label className={`btn ${selected == constants.WOW_IDEAS.MARKET?"btn-secondary":"btn-secondary"}  'active' `}>
                            <input type="radio" name="options" checked={selected == constants.WOW_IDEAS.MARKET} value={constants.WOW_IDEAS.MARKET} onChange={(el) => checkIdeasIconChange(el)} id="option2" autoComplete="off" /> Marketing
                        </label>
                    </div>
                </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-hover mb-0 align-items-center">
                        <thead>
                            <tr>
                                <th className='text-center'>Id</th>
                                <th>Ideas</th>
                                <th>Type</th>
                                <th className='d-flex me-4 justify-content-end'>
                                    <span className='me-5'>Actions</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {ideaList?.map((item, index) => {
                                return (<tr key={index}>
                                    <td className='text-center ps-0'>{index + 1}</td>
                                    <td>{item.idea_title}</td>
                                    <td>{typeName}</td>
                                     {item.is_default && authUser?.role_id != constants?.ADMIN_ROLE_ID ? <td></td> : 
                                    <td className='d-flex justify-content-end'>
                                        <span className="me-5">
                                            <Service title={"Wow Idea"} id={item?.id} oldValue={item?.idea_title}  type={item?.idea_type} />
                                            <button type="button" 
                                                className="btn btn-delete ms-4" data-bs-toggle="modal" href="#deleteIdea" role="button"
                                                disabled={item.is_default && authUser?.role_id != constants?.ADMIN_ROLE_ID ? true : false}
                                                onClick={() => { onClick(optionsWithLabelChange, item?.id, dispatch) }}
                                                title="Delete">
                                                <i className="fa-solid fa-trash-can"></i>
                                            </button>                                     
                                       </span>
                                    </td>
                                     } 
                                </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}


export default Ideas