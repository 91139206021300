import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { usePrompt } from "../../../modules/app/clientScoring/dirtyFormCheck";
import { saveRequired } from "../../../redux/actions/actionCreators";
import {getUserProfile} from './../../../redux/actions/actionCreators'
import constants from './../../constants'

const Menu = () => {
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_APP_URL;
  const API_URL = process.env.REACT_APP_API_URL
  
  const APP_URL = process.env.REACT_APP_APP_URL;
  const navigate = useNavigate();
  const location = useLocation();
  const { constants, actionRequired, authUser } = useSelector(
    (state) => state?.dataPack
  );

  // We are closing menu when clicking on the menu items
  const closeMenu = (event, url) => {
    
    if (actionRequired) {
      if (window.confirm(constants?.POPUP_MODAL_MESSAGE)) {
        /** save action notification */
        dispatch(saveRequired(false));
        navigate(url, { replace: true });
        let element = document.getElementById("wowMenu");
        element.classList.remove("show");
      }
    } else {
      navigate(url, { replace: true });
      let element = document.getElementById("wowMenu");
      element.classList.remove("show");
    }
  };
  // We are closing services dropdown when clicking on the sub-menu items
  const closeServices = (event) => {
    let element = document.getElementById("services-menu");
    element.classList.remove("show");
  };
  // We are closing settings dropdown when clicking on the sub-menu items
  const closeSettings = (event) => {
    let element = document.getElementById("settings-menu");
    element.classList.remove("show");
  };

  // Routing user through a function
  const navigateUser = (url) => {
    navigate(url, { replace: true });
  };

  const { userProfile } = useSelector((state) => state?.dataPack)

  const userData = userProfile ? userProfile?.values()?.next()?.value : []

  return (
    <div class="header-wrapper d-flex align-items-center d-lg-block">
      <div className="header-top">
          <div className="container-fluid px-2">
              <div className="row align-items-center">
                  <div className="col-md-4">
                      <div className="brand-logo d-flex justify-content-md-center">
                      <a href={BASE_URL ?? "https://proadvisordrivers.com"}>
                          <img
                          src={`${APP_URL}assets/logo-main.png`}
                          alt="Pro Advisor Drivers Logo"
                          />
                      </a>
                      </div>
                  </div>
                  <div className="col-md-4">
                  </div>
                  
                  <div className="col-md-4 d-none d-lg-flex justify-content-center">
                      <ul className="d-flex">
                          <li>
                              {
                                  authUser?.status &&
                                  <div className="profile-dropdown dropdown">
                                      <a className="btn d-flex align-items-center p-0" href="https://proadvisordrivers.com" role="button" id="avatarOptions" data-bs-toggle="dropdown" aria-expanded="false">
                                          <span className="user-avatar">
                                              <img src={API_URL+'storage/pics/'+userData?.user_image} onError={(e) => e.target.src = constants?.DEFAULT_PROFILE} alt={userData?.profile?.firstName} />
                                          </span>
                                          <span className="ms-2">
                                              <p>Welcome <strong className="user-name">{userData?.name} !</strong></p>
                                          </span>
                                      </a>
                                  </div>
                              }
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
      <div className="header-bottom align-self-center">
        <div className="container-fluid">
          <div className="row justify-content-lg-between m-0">
            <nav className="navbar col-12 pb-lg-0 position-static">
              <nav className="navbar navbar-expand-lg navbar-light w-100 p-0 position-static">
                <div className="container-fluid p-0 px-lg-2 justify-content-end justify-content-lg-between">
                  <button
                    className="navbar-toggler border-0 m-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#wowMenu"
                    aria-controls="wowMenu"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>

                  </button>
                  <div
                    className="collapse navbar-collapse d-lg-flex align-items-lg-end justify-content-lg-between"
                    id="wowMenu"
                  >
                    <div className="navbar-nav menu-right align-items-lg-end left-navigation">
                      <a
                        href={BASE_URL + "dashboard"}
                        className={`nav-link`}
                        onClick={(el) => closeMenu(el)}
                      >
                        Dashboard
                      </a>
                      <a
                        href={BASE_URL + "v-map"}
                        className={`nav-link`}
                        onClick={(el) => closeMenu(el)}
                      >
                        V-MAP
                      </a>
                      <div className="dropdown">
                        <Link
                          to="#"
                          className={`btn border-0 nav-link dropdown-toggle ${
                            (location?.pathname == `/ideas` ||
                              location?.pathname == `/responsible-person` ||
                              location?.pathname == `/value-added-grid` ||
                              location?.pathname == `/relational-grid-service` ||
                              location?.pathname == `/gap-analysis` ||
                              location?.pathname == `/vip-tracker`) &&
                            "active"
                          }`}
                          role="button"
                          id="activityDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Services
                        </Link>
                        <ul
                          id="services-menu"
                          className="dropdown-menu p-0"
                          aria-labelledby="activityDropdown"
                          onClick={(el) => closeServices(el)}
                        >
                          <li>
                            <p
                              className={`dropdown-item ${
                                location?.pathname == `/responsible-person` &&
                                "active"
                              }`}
                              onClick={(el) =>
                                closeMenu(el, "/responsible-person")
                              }
                            >
                              Responsible Persons
                            </p>
                          </li>
                          <li>
                            <p
                              className={`dropdown-item ${
                                location?.pathname == `/ideas` && "active"
                              }`}
                              onClick={(el) => closeMenu(el, "/ideas")}
                            >
                              Ideas
                            </p>
                          </li>
                          <li>
                            <p
                              className={`dropdown-item ${
                                location?.pathname == `/value-added-grid` &&
                                "active"
                              }`}
                              onClick={(el) => closeMenu(el, "/value-added-grid")}
                            >
                              Relational Currency Service
                            </p>
                          </li>
                          <li>
                            <p
                              className={`dropdown-item ${
                                location?.pathname ==
                                  `/relational-grid-service` && "active"
                              }`}
                              onClick={(el) =>
                                closeMenu(el, "/relational-grid-service")
                              }
                            >
                              Relational Currency WOW!
                            </p>
                          </li>
                          <li>
                            <p
                              className={`dropdown-item ${
                                location?.pathname == `/gap-analysis` && "active"
                              }`}
                              onClick={(el) => closeMenu(el, "/gap-analysis")}
                            >
                              GAP Analysis
                            </p>
                          </li>
                          <li>
                            <p
                              className={`dropdown-item ${
                                location?.pathname == `/vip-tracker` && "active"
                              }`}
                              onClick={(el) => closeMenu(el, "/vip-tracker")}
                            >
                              VIP Wow Tracker
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="navbar-nav menu-right navbar-right align-items-lg-end">
                      <div className="dropdown">
                        <Link
                          to="#"
                          className={`btn border-0 nav-link dropdown-toggle ${
                            (location?.pathname == "/admin/tiers" ||
                              location?.pathname ==
                                `/manage/areas/list/${constants?.METRIC_TYPE?.DEMOGRAPHIC}` ||
                              location?.pathname ==
                                `/manage/areas/list/${constants?.METRIC_TYPE?.PSYCHOGRAPHIC}`) &&
                            "active"
                          }`}
                          role="button"
                          id="activityDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Settings
                        </Link>

                        <ul
                          id="settings-menu"
                          className="dropdown-menu p-0 rounded-0"
                          aria-labelledby="activityDropdown"
                          onClick={(el) => closeSettings(el)}
                        >
                          <li>
                            <p
                              className={`dropdown-item ${
                                location?.pathname ==
                                  `/manage/areas/list/${constants?.METRIC_TYPE?.DEMOGRAPHIC}` &&
                                "active"
                              }`}
                              onClick={(el) =>
                                closeMenu(
                                  el,
                                  `/manage/areas/list/${constants?.METRIC_TYPE?.DEMOGRAPHIC}`
                                )
                              }
                            >
                              Demographics
                            </p>
                          </li>
                          <li>
                            <p
                              className={`dropdown-item ${
                                location?.pathname ==
                                  `/manage/areas/list/${constants?.METRIC_TYPE?.PSYCHOGRAPHIC}` &&
                                "active"
                              }`}
                              onClick={(el) =>
                                closeMenu(
                                  el,
                                  `/manage/areas/list/${constants?.METRIC_TYPE?.PSYCHOGRAPHIC}`
                                )
                              }
                            >
                              Psychographics
                            </p>
                          </li>
                          <li>
                            <p
                              className={`dropdown-item ${
                                location?.pathname == `/admin/tiers` && "active"
                              }`}
                              onClick={(el) => closeMenu(el, `/admin/tiers`)}
                            >
                              Tiers
                            </p>
                          </li>
                        </ul>
                      </div>
                      <p
                        className={`nav-link ${
                          (location?.pathname == "/demographics" ||
                            location?.pathname == "/") &&
                          "active"
                        }`}
                        onClick={(el) => closeMenu(el, "/demographics")}
                      >
                        Demographics
                      </p>
                      <p
                        className={`nav-link ${
                          location?.pathname == "/demographic-metrics" && "active"
                        }`}
                        onClick={(el) => closeMenu(el, "/demographic-metrics")}
                      >
                        Demographics Metrics
                      </p>
                      <p
                        className={`nav-link ${
                          location?.pathname == "/psychographics" && "active"
                        }`}
                        onClick={(el) => closeMenu(el, "/psychographics")}
                      >
                        Psychographics
                      </p>
                      <p
                        className={`nav-link ${
                          location?.pathname == "/psychographic-metrics" &&
                          "active"
                        }`}
                        onClick={(el) => {
                          closeMenu(el, "/psychographic-metrics");
                        }}
                      >
                        Psychographics Metrics
                      </p>
                      <p
                        className={`nav-link ${
                          location?.pathname == "/tiers" && "active"
                        }`}
                        onClick={(el) => closeMenu(el, "/tiers")}
                      >
                        Tiers
                      </p>
                      <p
                        className={`nav-link ${
                          (location?.pathname == "/client-scoring/demographic" ||
                            location?.pathname ==
                              "/client-scoring/psychographic") &&
                          "active"
                        }`}
                        onClick={(el) =>
                          closeMenu(el, "/client-scoring/demographic")
                        }
                      >
                        Client Scoring
                      </p>
                    </div>
                  </div>
                </div>
              </nav>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
