import Heading from './../../../components/layouts/heading'
import { useSelector, useDispatch } from "react-redux";
import { saveValueGridData, warningMsg, saveRequired } from './../../../redux/actions/actionCreators'
import { updateGapAnalysisValue, saveGapAnalysisGrid } from './../../../redux/actions/ListActions'
import DatePicker from "react-datepicker";
import moment from "moment";
import {usePrompt} from '../clientScoring/dirtyFormCheck'

const GapAnalysis = (props) => {

    const dispatch = useDispatch()

    const { clients,constants, actionRequired, gapAnalysisHeading, gapAnalysisValue, selectedListing } = useSelector((state) => state?.dataPack)
    usePrompt(constants?.POPUP_MODAL_MESSAGE,actionRequired)

    return (
        <div>

            <Heading title={"Client GAP Analysis"} listing={false} />
            <form onSubmit={(el) => handleSubmitEvent(el)}>
                <div className="table-wrapper">
                    <div className="checkbox-table-wrapper">
                        <div className="checkbox-table-head d-flex flex-wrap align-items-center justify-content-center justify-content-sm-between px-4 py-3">
                            <h2></h2>
                            <div className="add-new-wrapper d-flex flex-wrap align-items-center justify-content-between">
                                <button type="button" className={`btn text-white ${actionRequired && 'notify'}`} onClick={(el) => handleSubmitEvent(el)}><i className="fa-solid fa-floppy-disks"></i> Save</button>
                            </div>
                        </div>
                    </div>
                    <div className="client-gap-analysis-table-wrapper">
                        <div className="table-responsive">
                            <table className="table table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th scope="col">Name</th>
                                        <th width="10%">Value Conversation Date</th>
                                        {
                                            renderHeading()
                                        }
                                        <th>Notes</th>
                                        <th scope="col">Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        renderClients()
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )

    /**
     * render heading data list
     * 
     * @param {*} NA
     * @returns 
     */
    function renderHeading()
    {
        return gapAnalysisHeading?.map( (option, index) => {

            return (
                <th scope="col" key={index?.toString()}>{option?.heading}</th>
            )
        })
    }

    function renderClients()
    {
        return clients?.map((client, index) => {

            let options  = gapAnalysisValue?.clients?.filter(list => list?.id == client?.id )
            let option   = options?.values()?.next()?.value ?? false
          
            return (
                <tr key={index?.toString()}>
                    <td>{index + 1}</td>
                    <td>{ client?.name}</td>
                    <td>
                    <DatePicker scrollableYearDropdown={true} placeholderText='mm/dd/yyyy' selected={(client?.conversation_date)?new Date(client?.conversation_date):''} 
                        onChange={date => handleConversionChangeEvent(date, client, 'conversation_date',index)} />
                    </td>
                    {
                        renderSelection(client)
                    }
                    <td><input type="text" value={client?.note} className="form-control" onChange={(el) => handleConversionChangeEvent(el, client, 'note',index)}/></td>
                    <td>{calClientScore(client)}</td>
                </tr>
            )
        })
    }

    function calClientScore(client)
    {
        let totalScore = 0
        const { scores } = gapAnalysisValue

        let scoreData = scores?.filter( list => list?.client_id == client?.id )
        scoreData?.map(option => {
            totalScore  += parseInt(option?.score)
        })
        return totalScore
    }

    function renderSelection(client)
    {
        const { scores } = gapAnalysisValue
        
        return gapAnalysisHeading?.map( (option, index) => {
            
            let isOptions  = scores?.filter( list => list?.client_id == client?.id && list?.gap_analysis_heading_id == option?.id )
            let score = isOptions?.values()?.next()?.value?.score ?? false

            return (
                <td key={index?.toString()}>
                    <select className="form-select" onChange={(el) => handleChangeEvent(el, client, option)}>
                        <option value="0">0</option>
                        {
                            renderOptions(score)
                        }
                    </select>
                </td>
            )
        })
    }

    function renderOptions(score)
    {
        let options  = [1,2,3,4,5,6,7,8,9,10]

        return options?.map( (option , index) => {

            let isSelected  = (score == option) ? true : false

            return (
                <option selected={isSelected} value={option} key={index?.toString()}>{option}</option>
            )
        })
    }

    function handleChangeEvent(el, client, option)
    {
        el.preventDefault()

        let gapAnalysisScores = []

        let analysisValues  = gapAnalysisValue['scores'] ?? []

        let filterIndex  =  analysisValues?.findIndex( list => list?.client_id == client?.id && list?.gap_analysis_heading_id == option?.id )

        if( filterIndex >= 0 ){
            analysisValues[filterIndex]['score'] = el?.target?.value

        }
        else
        {
            analysisValues?.push({
                'gap_analysis_heading_id' : option?.id,
                'client_id' : client?.id,
                'score' : el?.target?.value
            })
        }
        
        gapAnalysisScores['scores'] = analysisValues
        gapAnalysisScores['clients'] = gapAnalysisValue['clients']

        dispatch(updateGapAnalysisValue(gapAnalysisScores))
        /** save action notification */
        dispatch(saveRequired(true))
    }

    function handleConversionChangeEvent(el, client, type,index)
    {
       if(type != 'conversation_date') el.preventDefault();
        
        let gapAnalysisScores = []

        let analysisValues  = gapAnalysisValue['clients'] ?? []

        let filterIndex  =  analysisValues?.findIndex( list => list?.id == client?.id )

        if(type == 'conversation_date'){
            clients[index].conversation_date = moment(el).format("YYYY-MM-DD");
        }  
        else{
            clients[index].note=el.target.value;
        }
       
        if( filterIndex >= 0 ){  
            analysisValues[filterIndex][type] = type == 'conversation_date' ? moment(el).format("YYYY-MM-DD") : analysisValues[filterIndex][type] = el?.target?.value;       
        }
        else
        {
            analysisValues?.push({
                'conversation_date' : type == 'conversation_date' ? moment(el).format("YYYY-MM-DD") : clients[index].conversation_date,
                'id' : client?.id,
                'note' : type == 'note' ? el?.target?.value : clients[index].note
            })
        }
   
        gapAnalysisScores['scores'] = gapAnalysisValue['scores']
        gapAnalysisScores['clients'] = analysisValues
        
        dispatch(updateGapAnalysisValue(gapAnalysisScores))
        /** save action notification */
        dispatch(saveRequired(true))
    }

    async function handleSubmitEvent(el) {

        el.preventDefault()

        let clients  = getClientUpdatedValues(gapAnalysisValue['clients'])
        let scores   = getClientUpdatedScores(gapAnalysisValue['scores'])
        
        gapAnalysisValue['listing_id'] = selectedListing?.value

        dispatch(saveGapAnalysisGrid({ listing_id: selectedListing?.value, clients, scores: scores }))
        /** save action notification */
        dispatch(saveRequired(false))
    }

    function getClientUpdatedValues(clients)
    {
        let dataValues  = []

        clients?.map( option => {
            dataValues?.push({
                'conversation_date' : option?.conversation_date,
                'id': option?.id,
                'note': option?.note
            })
        })
        return dataValues 
    }

    function getClientUpdatedScores(clients)
    {
        let dataValues  = []

        clients?.map( option => {
            dataValues?.push({
                'gap_analysis_heading_id' : option?.gap_analysis_heading_id,
                'client_id': option?.client_id,
                'score': option?.score
            })
        })
        return dataValues 
    }
}
export default GapAnalysis