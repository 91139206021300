import Tracker from './Tracker'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/** importing api actions functions */
import {getStatementListing, defaultListItem} from './../../../redux/actions/actionCreators'
import {getTierTrackerClientList, getTrackerIdeasList, getTrackerHeading, getTrackerDataValue} from './../../../redux/actions/ListActions'

const Index = () => {

    const { selectedListing,helper } = useSelector((state) => state?.dataPack)

    const dispatch = useDispatch()
    
    useEffect( async() => {
        helper?.setTitle()
        /** get all tracker ideas data */
        dispatch(getTrackerIdeasList())

        /** get tracker heading */
        dispatch(getTrackerHeading())

        /** get statement listing data */
        if( !selectedListing?.value ) 
            await dispatch(getStatementListing(defaultListItem))

        /** get all clients list */
        await dispatch(getTierTrackerClientList(selectedListing?.value))

        /** get client saved metric data */
        await dispatch(getTrackerDataValue(selectedListing?.value))

    }, dispatch)
    
    return (
        <Tracker />
    )
}

export default Index